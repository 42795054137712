.purchase-info{
    .order-info__block{
        &--1{
            width: 200px;
            @include reverse-breakpoint($medium) {
                padding-left: 0;
                padding-right: 0;
                width: 100%;
            }
        }
        &--2{
            width: 250px;
            @include reverse-breakpoint($medium) {
                padding-left: 0;
                padding-right: 5px;
                width: 50%;
            }
        }
        &--3{
            flex-grow: 0.5;
            width: 125px;
            @include reverse-breakpoint($medium) {
                padding-right: 0;
                width: 50%;
            }
            @include reverse-breakpoint($small) {
                padding-left: 5px;
                min-width: unset;
            }
        }
        &--4{
            flex-grow: 0.5;
            width: 125px;
            @include reverse-breakpoint($medium) {
                padding-left: 0;
                padding-right: 0;
                width: 100%;
            }
        }
        &--5{
            flex-grow: 1;        
            width: 250px;
            @include reverse-breakpoint($medium) {
                padding-left: 0;
                padding-right: 0;
                width: 100%;
            }
        }
    }
}
