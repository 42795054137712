@media print {
  body * {
    visibility: hidden;
  }
  
  #DocumentBodyGrid, #DocumentBodyGrid * {
    visibility: visible;
  }
  
  #DocumentBodyGrid {
    position: absolute;
    left: 0;
    top: 0;
  }
}