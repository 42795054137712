.receiving-product-variance{
    display: flex;
    flex-flow: column;
    height: 100%;
    
    .fa-redo{
        font-size: 26px;
        transform: scaleX(-1);
    }

    .variance-template{
        display: flex;
        flex-flow: row;
        padding: 5px 0;

        &__product-infos{
            display: flex;
            flex-flow: column;
            width: 80%;
            justify-content: center;
        }

        &__product-qty{
            display: flex;
            width: 20%;
            justify-content: center;
            align-items: center;
        }
    }
    .side-panel-100PercentWidth{
        height: 84%;
        display: flex;
        flex-flow: column;
    }
    .search-list__actions-all{
        padding: 3px;
    }
    .template-body__row{
        @include reverse-breakpoint($small){
            padding-top: 2px;
        }
        .checkbox-label{
            @include reverse-breakpoint($small){
                font-size: 85%;
            }
        }
    }
}

.full-height-middle {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .novariance {
        width: 100%;
        padding: 10px;
        text-align: center;
    }
}