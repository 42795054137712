$list-margin-top: 10px;
$list-margin-bottom: 10px;

.product-search{
    display: flex;
    flex-flow: column;
    height: 100%;

    .template-header {
        .template-header__center {
            padding: 0 15px;

            .template-header__title {
                flex-grow: 3;
                flex-direction: column;

                i {
                    font-size: 50px;

                    @include reverse-breakpoint($small) {
                        font-size: 35px;
                    }
                }

                span {
                    text-align: center;
                    width: 100%;
                }
            }
        }
    }

    .product-infos{
        display: flex;
        flex-flow: column;
        justify-content: center;
        height: 100%;
        width: calc(100% - 60px);
        padding: 5px 5px 5px 20px;
        line-height: 20px;
    
        .product-description{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: bold;
        }
    }
}

