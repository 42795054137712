.label {
    font-weight: bold;
    margin: 10px;
}

.actionrow {
    display: flex;
    justify-content: space-between;

}

.searchbar {
    min-width: 400px;
    width: 50%;
}

