
        @import '@/core/assets/scss/abstracts/__variables.scss';
        @import '@/core/assets/scss/abstracts/_animations.scss';
        @import '@/core/assets/scss/abstracts/_fonts.scss';
        @import '@/core/assets/scss/abstracts/_functions.scss';
        @import '@/core/assets/scss/abstracts/_mixins.scss';
        @import '@/core/assets/scss/abstracts/_placeholders.scss';
        

.grid-wrap {
  width: 100%;
  height: 100%;
  padding-top: 10px;
  z-index: 0;
  
  .ag-center-cols-clipper{
    z-index: 1;
  }
  .ag-full-width-container{
    z-index: 0;
  }
}

.container{
  .input-wrap--number{
      @include reverse-breakpoint($small) {
          padding: 0 2px;
      }
  }

  &.second-info-displayed {
      flex-flow: column;
      align-items: flex-end;
      justify-content: space-evenly;

      .second-info {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 80px;
          height: 15px;
      }
  }
}

/*
  AG-GRID OVERWRITES
  if you plan on adding anything below, please make sure to document it for futur debug help
*/

.ag-overlay {
  z-index: 9999;
}

//https://tfs.sirsolutions.com/DefaultCollection/Eridani/_wiki/wikis/Eridani.wiki/282/La-classe-grid-wrap-mobile
.grid-wrap--mobile, .grid-wrap--headerless{
  .ag-header{
    display: none;
  }
  .ag-ltr .ag-cell{
    padding: 0;
    border: none;
  }
}
.grid-wrap--mobile{
  .ag-row-selected::before{
    background-color: palette('white', 'base');
  }
  .dropdown-cellrenderer{
    background-color: palette('white', 'base');
  }
}

//https://tfs.sirsolutions.com/DefaultCollection/Eridani/_wiki/wikis/Eridani.wiki/493/Lignes-s%C3%A9lectionn%C3%A9es-avec-le-style-balham-est-bleut%C3%A9
.ag-theme-balham .ag-row-selected::before{
  background-color: palette('white', 'base');
}

//https://tfs.sirsolutions.com/DefaultCollection/Eridani/_wiki/wikis/Eridani.wiki/285/Bordure-de-cellule-modifie-sa-largeur
.ag-theme-material .ag-cell, .ag-theme-alpine .ag-cell {
  border: none;
  display: flex;
  align-items: center;
}

//https://tfs.sirsolutions.com/DefaultCollection/Eridani/_wiki/wikis/Eridani.wiki/280/Bordure-d'une-cellule-de-grille-en-mobile
.ag-theme-material .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-material .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected){
  border: none;
}

//https://tfs.sirsolutions.com/DefaultCollection/Eridani/_wiki/wikis/Eridani.wiki/495/Bordure-d'une-cellule-avec-le-th%C3%A8me-balham
.ag-theme-balham .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within{
  border: none;
}

//https://tfs.sirsolutions.com/DefaultCollection/Eridani/_wiki/wikis/Eridani.wiki/496/Background-en-hover-d'une-ligne-s%C3%A9lectionn%C3%A9-th%C3%A8me-balham
.ag-theme-balham .ag-row-hover.ag-row-selected::before{
  background-image: none;
}

//https://tfs.sirsolutions.com/DefaultCollection/Eridani/_wiki/wikis/Eridani.wiki/279/Taille-et-couleur-de-police
.ag-theme-material, .ag-theme-alpine {
  color: palette('grey', 'dark');
  font-family: font('source-regular');
}

//https://tfs.sirsolutions.com/DefaultCollection/Eridani/_wiki/wikis/Eridani.wiki/278/Styles-des-ent%C3%AAtes-de-colonnes
.ag-theme-material, .ag-theme-alpine {
  .ag-header {
    .ag-header-cell-text, .ag-header-group-text{
      font-family: 'HelveticaNeue';
      letter-spacing: 0.5px;
      color: palette('grey', 'dark');
      font-size: 12px;
      text-align: center;
    }
  }
}
//https://tfs.sirsolutions.com/DefaultCollection/Eridani/_wiki/wikis/Eridani.wiki/277/Menu-pour-modifier-ou-supprimer-un-rapport
.ag-theme-material, .ag-theme-alpine{
  .ag-cell-overflow{
    overflow: visible;
    //https://tfs.sirsolutions.com/DefaultCollection/Eridani/_wiki/wikis/Eridani.wiki/287/Le-menu-d'une-cellule-n'est-pas-align%C3%A9
    .ag-cell-value{
      width: 100%;  
      overflow: visible;
    }
  }
  .ag-row-selected{
    z-index: 1;
  }
}
//https://tfs.sirsolutions.com/DefaultCollection/Eridani/_wiki/wikis/Eridani.wiki/284/Marge-d'ent%C3%AAte-de-colonne-et-de-cellule
@include reverse-breakpoint($medium) {
  .ag-theme-material, .ag-theme-alpine{
    .ag-header-cell, .ag-cell{
      padding-left: 10px;
      padding-right: 10px;
    }
  } 
}

.ag-theme-alpine {
  .ag-row.ag-row-selected::before {
    background-color: rgba(palette('blue', 'base'), 0.1);
  }
  .ag-row.ag-row-hover::before, 
  .ag-row.ag-row-selected.ag-row-hover::before {
    background-color: rgba(palette('blue', 'semiDark'), 0.1);
    background-image: none;
  }
}

.ag-theme-alpine .ag-header-row.ag-header-row-column {
  background-color: palette('white', 'base');
}

.clickable-headers .ag-full-width-container[role="rowgroup"] {
  z-index: 1;
}