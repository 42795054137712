.main-block {
  background-color: #fff;
  border-bottom: 2px solid palette('grey', 'semiLight');
  color: palette('grey', 'semiDark');
  display: block;
  font-family: font('source-bold');
  width: 100%;
  margin: 30px 0 0 0;
}

.main-block__label {
  @include font-smoothing();

  border-bottom: 1px solid palette('grey', 'light');
  cursor: pointer;
  display: flex;
  font-family: font('source-bold');
  height: 60px;
  justify-content: space-between;
  line-height: 60px;
  padding: 0 $gutter-desktop;
  text-transform: uppercase;
  transition: color 0.3s ease;
  user-select: none;

  .svg {
    fill: palette('grey', 'semiDark');
  }

  &:hover {
    color: palette('blue', 'dark');

    .svg {
      fill: palette('blue', 'dark');
    }
  }
}

.main-block__label-arrow {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 10px;

  .svg {
    width: 100%;
  }
}

.main-block__content {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
  width: 100%;

  @include breakpoint($xsmall) {
    padding: 10px $gutter-desktop;
  }
}

.main-block__blocks {
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0;
  width: 50%;
  @include reverse-breakpoint($small) {
    justify-content: space-evenly;
    align-items: center;
    width: 100%
  }
}

.main-block__icon {
  align-content: flex-start;
  height: 120px;
  margin: 0 30px;
  width: 120px;

  @include reverse-breakpoint($xsmall) {
    width: 70px;
    height: 70px;
    margin: 0;
  }
}

.main-block__text {
  font-family: font('source-regular');
  font-size: 0.8em;
}

.main-block__text--uppercase {
  font-family: font('source-bold');
  text-transform: uppercase;
}

.main-block__text--bold {
  font-family: font('source-bold');
  font-size: 2em;
  margin-top: 0;

  @include reverse-breakpoint($xsmall) {
    font-size: 1.2em;
    margin-top: 10px;
  }
}
