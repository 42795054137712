.general-ledger-account{
    display: flex;
    padding-top: 20px;

    #subdepartmentGridId, 
    #paymentGridId, 
    #taxGridId {
        min-height: 300px;  
    }    

    &__subdepartment,
    &__payment,
    &__tax{  
        display: flex;
        flex-flow: column;
        padding: 10px 0;
    }

    .uneditable{
        font-weight: bold;
        opacity: 0.5;
    }
}