
        @import '@/core/assets/scss/abstracts/__variables.scss';
        @import '@/core/assets/scss/abstracts/_animations.scss';
        @import '@/core/assets/scss/abstracts/_fonts.scss';
        @import '@/core/assets/scss/abstracts/_functions.scss';
        @import '@/core/assets/scss/abstracts/_mixins.scss';
        @import '@/core/assets/scss/abstracts/_placeholders.scss';
        
.description-wrap{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-left: 2px solid #e8e8e8;

  > input:disabled {
    background-color: #ffffff;
  }
}
.language-wrap{
  position: relative;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  width: 125px;
  height: 100%;
  border: none;
  border-right: 2px solid #e8e8e8;
  border-radius: 5px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  cursor: pointer;
  .language-wrap__value{
    height: 100%;
    width: 60%;
    display: flex;
    align-items: center;
    -moz-appearance: none;
    -webkit-appearance: none;
    outline: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
  }
}
.language__li{
  border-bottom: 1px solid #efeeed;
  border-radius: 5px;
  color: #2c2f34;
  cursor: pointer;
  list-style-type: none;
  padding: 10px 10px;
  text-align: left;
  &:hover{
    background: rgba(204, 204, 204, 0.25);
  }
}
.lang__label-arrow {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 40%;
  cursor: pointer;
  &:hover{
    fill: palette('blue', 'dark');
  }
  .svg {
    width: 6px;
  }
}
.dropdown {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
.dropdown-content {
  border-radius: 5px;
  position: absolute;
  top: 2px;
  left: -20px;
  background-color: #ffffff;
  min-width: 140px;
  -webkit-box-shadow: 0 0 20px 2px rgba(44, 44, 44, 0.4);
  box-shadow: 0 0 20px 2px rgba(44, 44, 44, 0.4);
  z-index: 1;
}
.select-wrap{
  width: 100px;
  height: 100%;
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
}
.input-wrap .multiselect{
  width: 150px;
  height: 100%;
}
.input-wrap .multiselect__element{
  &:hover {
    background: rgba(204, 204, 204, 0.250);
  }
  span{
    background: none;
    color: #40454c;
    &:after{
      display: none;
    }
  }
}
.input-wrap .multiselect__tags{
  padding: 0 40px 0 0;
  height: 100%;
  height: 100%;
  border: none;
  border-right: 2px solid #e8e8e8;
}
.input-wrap .multiselect__select{
  height: 100%;
}
.input-wrap .multiselect__input, .input-wrap .multiselect__single{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0px;
}
