.panel__renderer {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    height: 100%;
    width: 100%;
  
    span {
      //text-decoration: underline;
      color: #50c8e8;
      cursor: pointer;
    }
  
    svg {
      width: 25px;
      height: 25px;
      margin: 0 10px 0 0;
      cursor: pointer;
      fill: #50c8e8;
    }
  }