.breadcrumb{
  font-size: 18px;

   ul{
    display: flex;
    flex-flow: row;
    color: palette('grey', 'base');
    justify-content: end;

    li{
      list-style-type: none;
      padding: 0 5px;

      &.capitalized{
        text-transform: capitalize;
      }

      a {
        text-decoration: none;
        color: inherit;
      }
    }
    
    i{
      padding-left: 10px;
      font-size: 12px;
      color: palette('blue', 'semiDark');
      font-weight: bold;
    }
  }
}
