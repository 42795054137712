.discount-type-cell-renderer {
    .option__scope {
        font-size: 100%;
        font-weight: bold;
    }
    
    .option__icon {
        font-size: 11px;
        padding: 0 10px;
    }
    
    .option__description {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 100%;
    }
}