/*Group renderer css*/
.group-container{
    width:100%;
    display: flex;
    justify-content: space-between;
    padding: 0 23px;
    cursor: pointer;

    .group-container__row{
        width:100%;
    }
}