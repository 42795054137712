
        @import '@/core/assets/scss/abstracts/__variables.scss';
        @import '@/core/assets/scss/abstracts/_animations.scss';
        @import '@/core/assets/scss/abstracts/_fonts.scss';
        @import '@/core/assets/scss/abstracts/_functions.scss';
        @import '@/core/assets/scss/abstracts/_mixins.scss';
        @import '@/core/assets/scss/abstracts/_placeholders.scss';
        
.backend-filters{
    width: 100%;
    height: 100%;
    overflow: auto;

    @include reverse-breakpoint($medium){        
        overflow: unset;
    }

    .backend-filters__option{
        margin-bottom: 5px;
        position: relative;

        @include reverse-breakpoint($xsmall){
            margin-left: 5px;
        }

        .backend-filters__group{
            border-left: 3px solid #40454c;
            height: calc(100% - 10px);
            top: 5px;
            padding-left: 5px;
            position: absolute;
        }

        .backend-filters__group:after {
            content: "";
            border-color: transparent transparent transparent #40454c;
            border-style: solid;
            border-width: 5px;
            width: 0;
            height: 0;
            position: absolute;
            left: 0px;
            top: 34px;
        }

        .column-picker{
            display: flex;
            align-items: center;
            width: 100%;
            padding-bottom: 5px;
            padding-left: 5px;

            .model-column-picker{                
                width: calc(100% - #{$input-height} - 2px);
            }
        }
        .filter-input{
            padding-left: 5px;
        }
    }    
}