.form-wrap{
  display: flex;
  flex-flow: wrap;
  width: 100%;
  height: 100%;
}
.form-column{
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
}
.form-row{
  display: flex;
  flex-flow: wrap;
  width:100%;
  div:not(:last-child){
    padding-right:10px;
  }
}
.content{
  height: 90%;
}
.footer{
  height: 10%;
  display: flex;
  align-items: center;
}
.is-valid {
  content: "✓";
  color: green;
}  
.is-invalid {
  border: 1px #eb0600 solid;
}
  