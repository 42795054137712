.dashboard-container{
  height: 100%;
  width: 100%;
}

.dashboard-heading__controls{
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @include reverse-breakpoint($small) {
    flex-flow: column;
  }


  .dashboard-heading__options{
    display: flex;
  
    @include reverse-breakpoint($small) {
      flex-flow: column;
      width:100%;
    }
  
    .dashboard_heading__options-calendar{
      width: 225px;
      @include reverse-breakpoint($small) {
        width: 100%;
      }
    }
    .dashboard-heading__options-target{
      width: 250px;
      margin-right:10px;
      @include reverse-breakpoint($small) {
        margin: 10px 0 10px 0;
        width: 100%;
      }
    }


    .dashboard-heading__options-dashboard{
      display: flex;
      @include reverse-breakpoint($small) {
        width: 100%;
      }

      .input-wrap{
        padding-right: 15px;
      }

      .dashboard__select-option {
        display: flex;
        flex-flow: row;
        align-content: space-between;
    
        .dashboard__select-description {
            display: flex;
            width: calc(100% - 20px);
    
            span{
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    
        .dashboard__select-favorite {
            display: none;
            color: rgb(29, 29, 29);
            width: 20px;
            cursor: pointer;
            
            &.selected {
                color: #bdbd00;
                display: flex;
            }
        }
    }

      /*Multi-select dashboard overwrite*/
      .multiselect__single {
        color: palette('grey', 'semiDark');
        font-family: font('source-light');
        font-size: 1.5em;
        letter-spacing: 0.5px;
        @include breakpoint($medium) {
          font-size: 1.6em;
        }
      }
      
      .multiselect__option {
        .dashboard__select-favorite {
          display: flex;
        }
      }
    }
  }
}






