.detail-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  .detail-container {
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: 100%;
    padding: 5px 10px;
  
    .detail-block {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
    
      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 1;
      }
    }
  }
}