.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.layout__content {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
}
.layout__column {
  flex-grow: 1;
  height: 100%;
  padding: 0 $gutter-desktop;
}

.layout__column--dark {
  background-color: palette('grey', 'dark');
  color: #fff;
}

.layout__title {
  font-family: font('source-light');
  font-size: 1.5em;
  margin: 0 10px 20px;
}

.page {
  height: 100%;
  width: 100%;
  main{
    height: 100%;
  }
}
