.role-permissions {
    display: flex;
    flex-flow: column;
    height: 100%;

    .role-permissions__loading{
        display: flex;
        height: 100%;
        width: 100%;
    }
}