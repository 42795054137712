
        @import '@/core/assets/scss/abstracts/__variables.scss';
        @import '@/core/assets/scss/abstracts/_animations.scss';
        @import '@/core/assets/scss/abstracts/_fonts.scss';
        @import '@/core/assets/scss/abstracts/_functions.scss';
        @import '@/core/assets/scss/abstracts/_mixins.scss';
        @import '@/core/assets/scss/abstracts/_placeholders.scss';
        
.tabbable{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $step-height;
}
.nav-tabs.wizard {
  background-color: transparent;
  padding: 0;
  width: 100%;
  border-radius: .25em;
  clear: both;
  border-bottom: none;
  height: 100%;
  display: flex;
  flex-flow: row;
}
.step-number{
  width:35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: palette('blue','base');
  font-size:20px;
}
.step-wrap{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 100%;
  width: 100%;
  position: relative;
}
.step-desc{
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'source_sans_probold';
  font-size:23px;  
  padding-left: 20px;
}
.nav-tabs.wizard li>* {
  position: relative;
  color: palette('white','base');
  background-color: palette('blue','dark');
  border-color: #00539400;
  height: 100%;
  cursor: pointer;
}
.nav-tabs.wizard li:last-child > * {
  border-color: palette('blue','dark');
}

.nav-tabs.wizard li.completed > * {
  color: palette('white','base');
  background-color: #96c03d !important;
  border-color: #96c03d !important;
  border-bottom: none !important;
  height: 100%;
}

.nav-tabs.wizard li.active > * {
  color: palette('white','base');
  background-color: palette('blue','dark');
  border-color: palette('blue','dark');
  border-bottom: none !important;
  height: 100%;
}

.nav-tabs.wizard > li > div {
  font-size: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 7px;
}
.nav-tabs.wizard li {
  width: 100%;
  display: flex;
  flex-flow: row;
  height: 100%;
  opacity: 0.3;
}
.nav-tabs.wizard li.active {
  opacity: 1;
}
.nav-tabs.wizard li:last-child {
  border: none;
}
.step-span{
  display: flex;
  flex-flow: wrap;
  border-radius: 7px;
  &:nth-child(2){
    width: 9px;
    margin: 0px 1px;
    opacity: 0.85;
  }
  &:nth-child(3){
    width: 6px;
    margin: 0px 1px;
    opacity: 0.60;
  }
  &:nth-child(4){
    width: 3px;
    margin: 0px 1px;
    opacity: 0.40;
  }
}