
        @import '@/core/assets/scss/abstracts/__variables.scss';
        @import '@/core/assets/scss/abstracts/_animations.scss';
        @import '@/core/assets/scss/abstracts/_fonts.scss';
        @import '@/core/assets/scss/abstracts/_functions.scss';
        @import '@/core/assets/scss/abstracts/_mixins.scss';
        @import '@/core/assets/scss/abstracts/_placeholders.scss';
        
.drop-down{
  max-height: 500px;
  display: block;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 20px 2px rgba(44, 44, 44, 0.4);
  position: absolute;
  right: 0px;
  top: 55px;
  min-width: 200px;
  z-index: 10;

  &.drop-down__indentation{
    
    .drop-down__content{
      padding: 10px 0px;
      width: 335px;
      @include reverse-breakpoint($xsmall) {
        width: 100%;
      }
    
      .drop-down__search{
        padding: 0 10px;
      }

      .overflow-drop{
        max-height: 445px;
        overflow-y: auto;
        overflow-x: hidden;
      
        .drop-down__ul{
          padding: 0 20px;

          .drop-down__li {
            border-bottom: 2px solid palette('grey', 'light');
            color: palette('grey', 'dark');
            cursor: pointer;
            display: flex;
            list-style-type: none;
            padding: 10px 0;
            justify-content: initial;

            .indent--1, .indent--2{
              font-weight: bold;
            }

            @for $i from 1 through 2 {
              .indent--#{$i} {
                padding-left: calc(#{$i} * 15px);
              }
            }
          
            @for $i from 3 through 10 {
              .indent--#{$i} {
                padding-left: calc(#{$i} * 20px);
              }
            }
          }  
        }
      }    
    }
  }

  .drop-down__li{
    border-bottom: 1px solid #efeeed;
    color: #2c2f34;
    cursor: pointer;
    display: flex;
    justify-content: center;
    list-style-type: none;
    padding: 20px 10px;

    &:hover {
      background: rgba(204, 204, 204, 0.055);
    }
    &:last-child {
      border-radius: 5px;
    }         
  }

  @include reverse-breakpoint($xsmall) {
    position: fixed;
    top: 60px;
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 90%;
  }
}