.promotion-management{
    height: 100%;
    display: flex;
    flex-flow: column;

    .template-body{
        .promotion__id{
            input{
                width: 50%;
            }            
        }
        .promotion__endDate{
            width: 50%;
            padding: 0 0 0 5px
        }
        .promotion__startDate{
            width: 50%;
            padding: 0 5px 0 0
        }
        .promotion__code{
            text-align: left;
        }        
    }
}