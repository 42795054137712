.inventory-product-adjustment{
    display: flex;
    flex-flow: column;
    height: 100%;

    .template-body{

        .bold{
            font-size: 22px;
            font-family: font('source-semi-bold');

            @include reverse-breakpoint($small) {
                font-size: 18px;
            }
        }

        .result__row{
            height: 33%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title{
                width: 33.33%;   
                text-align: start;
                color: palette('grey', 'semiLight');
                font-family: 'source_sans_prosemibold';
                font-size: 14px;   
                
                @include reverse-breakpoint($small) {
                    font-size: 12px;
                }
            }
            .quantity{                    
                width: 33.33%;                      
                text-align: center;                
                color: palette('grey', 'semiDark');
                font-size: 20px;
    
                @include reverse-breakpoint($small) {
                    font-size: 16px;
                }
            }
            .value{                    
                width: 33.33%;
                text-align: end;
                color: palette('grey', 'semiDark');
                font-size: 20px;
    
                @include reverse-breakpoint($small) {
                    font-size: 16px;
                }
            }

            .reason{
                color: palette('grey', 'dark');
            }

            .negative-value{
                color: red
            }
        }
    }
}

