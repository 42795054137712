
.toggle {
  display: flex;
  justify-content: center;
  align-items: center;

  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    input {
      opacity: 0;
      width: 60px;
      height: 34px;
      z-index: 10;
      position: absolute;
      cursor: pointer;
    }

    input:indeterminate, input:checked {
      
      +.toggle-slider__round {

        &:before {

          -webkit-transform: translateX(26px);
          -ms-transform: translateX(26px);
          transform: translateX(26px);

          height: 24px;
          width: 24px;
          border-style: none;
        }
      }
    }

    input:checked {
      
      +.toggle-slider__round {
        border-color: palette('blue', 'semiDark');

        &:before {
          background-color: palette('blue', 'semiDark');
        }
      }
    }

    input:indeterminate {
      
      +.toggle-slider__round {
        border-color: palette('red', 'base');

        &:before {

          background-color: palette('red', 'base');
        }
      }
    }

    .toggle-slider__round {
      border-radius: 34px;
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(palette('grey', 'semiLight'), 0.5);
      -webkit-transition: .4s;
      transition: .4s;

      
      background-color: #fff;
      border-style: solid;
      border-width: 3px;
      border-color: palette('grey', 'semiLight');

      &.disabled {
        opacity: 0.3;
        cursor: default;
      }

      &:before {

        border-radius: 50%;
        position: absolute;
        content: "";
        height: 24px;
        width: 24px;
        left: 2px;
        bottom: 2px;

        background-color: palette('grey', 'semiLight');
        
        -webkit-transition: .4s;
        transition: .4s;
      }
    }

    &.small-toggle {
      width: 45px;
      height: 23px;
      input {
          width: 45px;
          height: 23px;
      }
      
      .toggle-slider__round {
          border-width: 1px;
    
          &:before {
              left: 3px;
              bottom: 3px;
              height: 15px;
              width: 15px;
          }
      }
    
      input:indeterminate, input:checked {
        +.toggle-slider__round {
          &:before {
              -webkit-transform: translateX(22px);
              -ms-transform: translateX(22px);
              transform: translateX(22px);
  
              height: 15px;
              width: 15px;
          }
        }
      }
    }
  }
}
