html,
body {
  background-image: url('@/img/background.svg');
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

html {
  font-size: 10px;
}

body {
  font-family: font('source-regular');
  font-size: 1.6rem; // 16px
}

path {
  pointer-events: none;
}

.search-bar-group-button {
  display: flex;
  align-items: center;
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 5px;
  height: 5px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	background-color: #818993;
	border-radius: 10px;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset;
}

/*HEIGHTS*/
@for $i from 1 through 100 {
  .h-#{$i} {
    height: 1% * $i;
  }
}

/*MARGINS*/

@for $i from 5 through 50 {
  .m-#{$i} {
    margin: #{$i}px;
  }
}
@for $i from 5 through 50 {
  .m-l-r-#{$i} {
    margin-left: #{$i}px;
    margin-right: #{$i}px;
  }
}
@for $i from 5 through 50 {
  .m-t-b-#{$i} {
    margin-top: #{$i}px;
    margin-bottom: #{$i}px;
  }
}
@for $i from 5 through 50 {
  .m-r-#{$i} {
    margin-right: #{$i}px;
  }
}
@for $i from 5 through 50 {
  .m-l-#{$i} {
    margin-left: #{$i}px;
  }
}
@for $i from 5 through 50 {
  .m-t-#{$i} {
    margin-top: #{$i}px;
  }
}
@for $i from 5 through 50 {
  .m-b-#{$i} {
    margin-bottom: #{$i}px;
  }
}

@for $i from 12 through 32 {
  .fs-#{$i} {
    font-size: #{$i}px;
  }
}

/*PADDINGS*/

@for $i from 5 through 50 {
  .p-#{$i} {
    padding: #{$i}px;
  }
}
@for $i from 5 through 50 {
  .p-l-r-#{$i} {
    padding-left: #{$i}px;
    padding-right: #{$i}px;
  }
}
@for $i from 5 through 50 {
  .p-t-b-#{$i} {
    padding-top: #{$i}px;
    padding-bottom: #{$i}px;
  }
}
@for $i from 5 through 50 {
  .p-r-#{$i} {
    padding-right: #{$i}px;
  }
}
@for $i from 5 through 50 {
  .p-l-#{$i} {
    padding-left: #{$i}px;
  }
}
@for $i from 5 through 50 {
  .p-t-#{$i} {
    padding-top: #{$i}px;
  }
}
@for $i from 5 through 50 {
  .p-b-#{$i} {
    padding-bottom: #{$i}px;
  }
}
.justify-between{
  justify-content: space-between;
}

.ellipsis{
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
