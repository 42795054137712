.promotion-conditions{
    height: 100%;
    display: flex;
    flex-flow: column;
    
    .promotion__discountMinMaxItems {
        width: 100%;
        display: flex;
        flex-flow: column;

        .promotion__discountMinMaxItems__label {
            width: 100%;
            padding-bottom: 10px;
        }

        .promotion__discountMinMaxItems__content {
            display: flex;
            flex-flow: row;
            justify-content: space-between;

            .promotion__discountMinMaxItems__content {
                display: flex;
                flex-flow: row;
                width: calc(50% - 5px);

                .promotion__discountMinMaxItems__content-value__field {
                    display: flex;
                    flex-flow: row;

                    .promotion__discountMinMaxItems__content-value__field-check {
                        display: flex;
                        flex-flow: column;
                        align-content: flex-start;
                        width: 30%;
                    }

                    .promotion__discountMinMaxItems__content-value__field-value {
                        display: flex;
                        flex-flow: column;
                        width: 100%;
                        padding-left: 20px;

                        span {
                            max-width: 200px;
                        }
                    }
                }
            }
        }
    }
    .promotion__limit{        
        width: 35%;
        @include reverse-breakpoint($small){
            width: 40%;
        }
        input{
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        &.template-body__row{
            padding: 0;
        }
    }
    .promotion__available{
        width: 65%;
        
        @include reverse-breakpoint($small){
            width: 60%;
        }
        padding-top: 0;
        input{
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: none;
        }
        span{
            .date{
                color: palette('red','base');
            }
            @include reverse-breakpoint($small){
                padding-bottom: 10px;
            }
        }
        &.template-body__row{
            padding: 0;
        }
    }
}