/*Dropdown renderer css*/
.dropdown {
    width: 100%;

    .dropdown-container {
        display: flex;
        height: 48px;

        .dropdown-container__head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 100%;
            color: palette('blue', 'base');
            cursor: pointer;

            &--disable {
                @extend .dropdown-container__head;
                color: palette('grey', 'semiLight');
                cursor: default;
            }
        }

        .dropdown-container__dropdown {
            display: flex;
            width: 50px;
            justify-content: center;
            align-items: center;
        }

        .dropdown-container__row {
            width: 100%;
        }
    }

    .dropdown-detail {
        padding: 0 20px;

        .dropdown-detail__infos {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 40px;
        }

        .dropdown-detail__wrap {
            border-top: 1px solid palette('grey', 'light')
        }
    }

    .dropdown-delete {
        display: flex;
        height: 40px;
        justify-content: end;
        align-items: center;
        border-top: 1px solid #efeeed;

        .dropdown-icon {
            cursor: pointer;
            font-size: 20px;
        }
    }
}