.receiving-management {
    display: flex;
    flex-flow: column;
    height: 100%;
    
    .order-template {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 10px 5px 10px 0;
        color: palette('grey', 'semiDark');
        line-height: 20px;
        font-size: 14px;
    
        div {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    
        .order-template__identity {
            display: flex;
            flex-flow: column;
            justify-items: flex-start;
            width: 50%;
            padding-right: 2px;
    
            .order-template__identity__orderno {
                font-family: font('source-bold');
            }
        }
    
        .order-template__info {
            display: flex;
            flex-flow: column;
            justify-items: flex-start;
            width: 35%;
            padding-right: 2px;
        }
    
        .order-template__command-products {
            display: flex;
            justify-content: flex-end;
            align-self: flex-end;
            font-family: font('source-bold');
            width: 15%;
        }
    }

    .search-list{
        .search-list__box, .search-list__list_empty{
            min-height: 300px;
        }
    }
}

