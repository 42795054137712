.search__container {
  display: flex;
  flex-grow: 1;
  font-family: font('source-regular');
  width: 100%;
}
.search-wrap{
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;

  input:disabled{
    background-color: palette('grey', 'gainsboro');
  }  
}
.search__container-filter {
  flex-wrap: wrap;
}

.search__icon {
  border-radius: 0 7px 7px 0;
  fill: palette('grey', 'dark');
  width: 50px;
  z-index: 4;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  &.clickable {
    cursor: pointer;
  }
}

.search__icon-svg {
  height: 20px;
  width: 20px;
}

.search__buttons {
  display: flex;
}

.search__button {
  align-items: center;
  display: flex;
  font-size: 0.8em;
  height: $input-height;
  min-height: $input-height;
  justify-content: center;
  @include breakpoint($small) {
    font-size: 1.1em;
  }
}

.filter__button {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 0.9em;
  height: 50px;
  margin: 5px 5px 0 0px;
  min-height: 50px;
  min-width: 100px;
  justify-content: center;
  align-items: center;
  @include breakpoint($small) {
    font-size: 1.1em;
  }
}

.filter__delete {
  color: palette('grey', 'base');
  cursor: pointer;
  text-decoration: underline;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.filter__cross {
  cursor: pointer;
  font-size: 0.9em;
  margin-left: 20px;
}

.search__top {
  width: 100%;
}
