.purchase-info {
    display: flex;
    flex-flow: wrap;
    line-height: 2;

    @include reverse-breakpoint($medium) {
        width: 100%;
        margin-top: unset;
        position: initial;
    }

    @include reverse-breakpoint($small) {
        position: initial;
    }

    .purchase-info__block {
        padding-left: 5px;
        padding-right: 5px;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }

        .purchase-info__subblock{
            display: flex;
            .purchase-info__element{
                padding-left: 5px;
                padding-right: 5px;
                
                &:first-child {
                    padding-left: 0;
                }
        
                &:last-child {
                    padding-right: 0;
                }
            }                        
        }

        .purchase-info__element {
            display: flex;
            flex-flow: column;
            justify-content: space-around;
            width: 100%;

            &.purchase-info__comment {
                height: 143px;
                padding-bottom: 0;

                textarea {
                    padding: 10px 20px;
                    resize: none;
                }
            }
        }

        .variance{
            color: palette('red', 'base');
            input{
                color: palette('red', 'base')
            }   
        }
    }

    label {
        display: flex;
        align-items: center;
        font-family: 'HelveticaNeue';
        letter-spacing: 0.5px;
        font-size: 12px;
        font-weight: bold;
        padding-bottom: 5px;
    }

    .purchase-info--bold {
        font-weight: bold;
        color: palette('grey', 'dark');
    }
}

.purchase-cell {
    background-color: palette('grey', 'light');

    &--indeterminate {
        font-weight: bold;
        color: red;

        .input-wrap--number {
            font-weight: bold;
            color: red;
        }
    }
}

.ag-theme-material{
    .ag-cell.purchase-cell {
        border: none;
        border-bottom: 1px solid;
        border-color: palette('grey', 'semiLight');
    }
    .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected).purchase-cell {
        border: none;
        border-bottom: 1px solid;
        border-color: palette('grey', 'semiLight');
    }
}