.order-management {
    display: flex;
    flex-flow: column;
    height: 100%;
    
    #saved-order{
        font-size: 15px;
    }

    .template-body { 
        .order-body__vendor {
            .multiselect__single {
                font-family: font('source-semi-bold');
                font-size: 14px;
                color: palette('grey', 'semiLight');
            }

            .multiselect__option {
                font-family: font('source-semi-bold');
                font-size: 14px;
                color: palette('grey', 'semiLight');
            }
        }

        .order-body__comment {
            flex-grow: 1;
            min-height: 150px;
            max-height: 300px;

            textarea{
                padding: 10px 20px;
                resize: none;
            }
        }
    }
}

