
        @import '@/core/assets/scss/abstracts/__variables.scss';
        @import '@/core/assets/scss/abstracts/_animations.scss';
        @import '@/core/assets/scss/abstracts/_fonts.scss';
        @import '@/core/assets/scss/abstracts/_functions.scss';
        @import '@/core/assets/scss/abstracts/_mixins.scss';
        @import '@/core/assets/scss/abstracts/_placeholders.scss';
        
.column-aggregate-picker {  
  display: flex;
  width: 100%;
  min-width: 0;

  .multiselect{
    &.slim-multiSelect{
      margin: 0;
      min-width: 40px;
      max-width: 50px;
      border-left: 1px solid rgba(136, 136, 136, 0.315);
    }  
  } 

  .slim-multiSelect.multiselect .multiselect__content-wrapper{
    right: 0;
  }

  .input-wrap{
    padding: 0;
  }
  .multiselect{
    margin: 0 20px;
  }
}