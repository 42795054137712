.periodMain-container{
  position: relative;
}
.choice__arrow{
  display: flex;
  justify-content: center;
  align-items: center;
  svg{
    width: 9px;
    height: 9px;
    margin-left: 15px;
  }
}
.period-value{
  width: 100%;
  height: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.input-border{
  border: 1px solid palette('blue', 'base');
}
.periodMain-options--border{
  border:1px solid #efeeed;
}
.periodMain-options {
  background: #fff;
  border-radius: 5px;
  box-shadow: 10px 10px 15px -10px rgba(44, 44, 44, 0.4);
  display: none;
  width: 170px;
  z-index: 1;
  vertical-align: top;

  &--active {
    display: inline-block;
    position: absolute;
  }

  &--subActif {
    width: 175px;
    display: inline-block;
    position: absolute;
    left: 170px;
  }

  .periodMain__li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid palette('grey', 'light');
    border-left: 1px solid palette('grey', 'light');
    color: palette('grey', 'dark');
    cursor: pointer;
    list-style-type: none;
    padding: 10px 10px;
    text-align: left;
    font-family: font('source_sans_prolight');

    &:hover {
      background: rgba(204, 204, 204, 0.250);
    }
    button{
      width:100%;
      text-align: left;
    }
  }
}
.calendar__label-arrow {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 20px;
  height: 100%;
  cursor: pointer;
  &:hover{
    fill: palette('blue', 'dark');
  }
  .svg {
    width: 6px;
  }
}