$s: 0.3s;

$palettes: (
  'white':(
    'base': #fff,
  ),
  'green': (
    'base': #40b282
  ),
  'grey': (
    'light': #efeeed,
    'gainsboro': #d8d8d8,
    'semiLight': #818993,
    'base': #51575d,
    'semiDark': #40454c,
    'dark': #2c2f34,
  ),
  'blue': (
    'base': #50c8e8,
    'semiDark': #287dbf,
    'dark': #005394,
  ),
  'red':(
    'base': #ff3c3c,
  )
);

$fonts: (
  sans-serif: #{ HelveticaNeue },
  serif: #{ Georgia, Times, serif },
  source-light: #{ 'source_sans_prolight' },
  source-regular: #{ 'source_sans_proregular' },
  source-semi-bold: #{ 'source_sans_prosemibold' },
  source-bold: #{ 'source_sans_probold' },
);

// Breakpoints.
$xxsmall: 320px;
$xsmall: 480px;
$small: 768px;
$medium: 1024px;
$large: 1200px;
$xlarge: 1400px;
$xxlarge: 1921px;

// POS variables.
$pos-min-width: 1024px;

// Global variables.
$header-desktop-height: 55px;
$footer-desktop-height: 45px;
$header-footer-desktop-height: $header-desktop-height + $footer-desktop-height;
$gutter-desktop: 25px;

// BO variables.
$side-nav-desktop-open-width: 350px;
$side-nav-desktop-closed-width: 80px;
$heading-padding-top: 20px;
$heading-padding-bottom: 20px;
$heading-title-height: 35px;

// Step component variables
$step-height: 55px;

// Span height
$span-height: 42px;

// Input Variables
$input-height: 42px;

// Buttons variables
$button-height: 42px;

// Side-panel templates variables
$template-body-sides-padding: 15px;
$product-info-header-height: 125px;
$product-body-results-height: 175px;
$product-body-costs-height: calc(100% - #{$product-body-results-height} - #{$product-info-header-height});