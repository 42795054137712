.tree-data-grid {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    
    .tree-data__filters {
        display: flex;

        .active__button {
            display: block;
            width: 20%;
            min-width: 0;
            min-height: $button-height;
            margin-left: 10px;
            padding: 0 5px;
            fill: white;
            color: white;

            .fas {
                color: white;
                font-size: 12px;
            }

            &--text {
                font-size: 16px;
                padding: 0 2px;
            }
        }
    }

    .tree-data__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .tree-data__title {
            font-size: 24px;
            font-weight: bold;
        }
    }

    .tree-data__grid {
        height: 100%;
        width: 100%;
        min-height: 300px;

        /*AG GRID STYLES*/
        .grid-wrap--headerless .ag-ltr .ag-cell {
            &.tree-data-cell {
                display: flex;
                align-items: center;
                padding-left: 5%;

                .ag-cell-wrapper {
                    width: 100%;
                    position: relative;
                }

                .ag-icon-tree-open {
                    display: flex;
                }

                .ag-icon-tree-closed {
                    display: flex;

                    &::before {
                        transform: rotate(-90deg);
                    }
                }

                .ag-icon-tree-open::before,
                .ag-icon-tree-closed::before {
                    position: relative;
                    right: 0;
                    color: palette('grey', 'semiLight');
                    margin-top: 4px;
                    border-color: palette('grey', 'semiLight') transparent transparent;
                    border-style: solid;
                    border-width: 7px 5px 0;
                    content: "";
                }

                &.disabled{
                    .ag-checkbox-input {
                        pointer-events: none;
                    }
                }

                .ag-checkbox-input {
                    cursor: pointer;
                }

                .ag-checkbox-input-wrapper::after {
                    content: "";
                }

                &.disabled{  
                    .ag-checkbox-input-wrapper
                    .ag-checkbox-input-wrapper.ag-indeterminate::after,
                    .ag-checkbox-input-wrapper.ag-checked::after{
                        opacity: 0.5;
                    }              
                }

                .ag-checkbox-input-wrapper {
                    height: 20px;
                    width: 20px;
                    border: 1px solid palette('grey', 'semiLight');
                    border-radius: 5px;
                }

                .ag-checkbox-input-wrapper.ag-indeterminate::after {
                    content: "\2012";
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    top: -2px;
                    color: palette('grey', 'semiDark');
                }

                .ag-checkbox-input-wrapper.ag-checked::after {
                    content: "";
                    left: 6px;
                    top: 3px;
                    width: 6px;
                    height: 9px;
                    border-color: palette('grey', 'semiDark');
                    border-style: solid;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                }
            }
        }

        .ag-theme-balham .ag-root-wrapper{
            border-radius: 5px;
        }

        .ag-row {
            .tree-data-cell {
                font-size: 16px;
                font-family: 'source_sans_prosemibold';
            }
        }

        .ag-row-group {
            .tree-data-cell {
                font-family: 'source_sans_probold';
            }
        }

        .ag-theme-balham .ag-row {
            border-color: palette('grey', 'light');
        }
        
        @for $i from 1 through 10 {
            .ag-row-group .ag-row-group-indent-#{$i} {                
                margin-left: 0;
                padding-left: calc(#{$i} * 20px);
            }
        }
        @for $i from 1 through 10 {
            .ag-row-group-indent-#{$i} {                
                margin-left: 0;
                padding-left: calc(#{$i} * 22px);
            }
        }
    }
}