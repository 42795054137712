.container{
  height: 100%;
  display: flex;
  align-items: center;

  .input-wrap--number{
    height: 25px;
    width: 80px;
    padding: 0 10px;
  }
}