.input-button-root{
  display: flex;
  flex-direction: column;
  .input-button{
    display: flex;
    flex-direction: row;
    width: 100%;
  
    .input{
      width: 80%;
      height: 42px;
      padding-left: 10px;
      border-radius: 6px 0 0 6px;
      border: solid 0.5px #c3c2c2b0;
    }
    .button-part{
      width: 20%;
      border-radius: 0 6px 6px 0;
    }
  }
}
