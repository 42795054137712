.inventory-count{
    display: flex;
    flex-flow: column;
    height: 100%;
    
    .inventory-count__comment{
        flex-grow: 1;
        min-height: 150px;
        max-height: 200px;
    }
}

