.receiving-product{
    display: flex;
    flex-flow: column;
    height: 100%;
    
    .product-costs__row{
        .bold{
            font-weight: bold;
        }
        
        .input-wrap--number{
            width: 80px;
        }
    }    
}