.receiving-product-scan {
    display: flex;
    flex-flow: column;
    height: 100%;

    .receiving-product-scan-body__search {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        bottom: 0;
        padding: 25px;
        color:palette('blue', 'dark');
        i{
            cursor: pointer;
            font-size:40px;

            @include reverse-breakpoint($small) {
                font-size: 25px;
            }
        } 
    }
    
    .receiving-product-scan-body__invalid {
        display: flex;
        flex-flow: column;
        height: 100%;
        width: 80%;
        justify-content: center;
        align-items: center;
        color:palette('red', 'base');
    
        span {
            font-family: 'source_sans_proregular';
            font-size: 40px;
            padding-bottom: 20px;
            text-align: center;
        }
    }
}

