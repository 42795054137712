
        @import '@/core/assets/scss/abstracts/__variables.scss';
        @import '@/core/assets/scss/abstracts/_animations.scss';
        @import '@/core/assets/scss/abstracts/_fonts.scss';
        @import '@/core/assets/scss/abstracts/_functions.scss';
        @import '@/core/assets/scss/abstracts/_mixins.scss';
        @import '@/core/assets/scss/abstracts/_placeholders.scss';
        
.favorite-title {
  padding-right: 20px;
}

.select-option {
  display: flex;
  flex-flow: row;
  align-content: space-between;

  .select-description {
    display: flex;
    padding-right: 20px;
    width: calc(100% - 20px);

    span{
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .select-favorite {
    display: none;
    color: rgb(192, 186, 186);
    font-weight: bold;
    width: 20px;
    cursor: pointer;
    
    &.selected {
      color: #0609b3;
      display: flex;
    }
  }
}

.multiselect__singleLabel {
  color: palette('grey', 'semiDark');
  font-family: font('source-light');
  font-size: 25.6px;
  letter-spacing: 0.5px;
  padding-right: 15px;
}

.multiselect__select {
  padding: 0 20px;
}

.multiselect__option {
  .select-favorite {
    display: flex;
  }
}