@mixin font-smoothing() {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin breakpoint($resolution) {
  @media (min-width: $resolution) {
    @content;
  }
}

@mixin reverse-breakpoint($resolution) {
  @media (max-width: ($resolution - 1)) {
    @content;
  }
}
