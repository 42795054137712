.listing-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 30px 0 0 0;
  padding-left: 5px;
  float: left;
  height: 570px;
}

.listing-block__blocks {
  background-color: #fff;
  border-bottom: 2px solid palette('grey', 'semiLight');
  color: palette('grey', 'semiDark');
  display: block;
  flex-grow: 1;
  font-family: font('source-regular');
  min-width: 250px;
  width: 32%;
  height: 100%;
}

.listing-block__label {
  border-bottom: 1px solid palette('grey', 'light');
  cursor: pointer;
  display: flex;
  font-family: font('source-bold');
  height: 60px;
  justify-content: space-between;
  align-items: center;
  padding: 0 $gutter-desktop;
  text-transform: uppercase;
  transition: color 0.3s ease;
  user-select: none;

  &:hover {
    color: palette('blue', 'dark');

    .svg {
      fill: palette('blue', 'dark');
    }
  }
}

.listing-block__label-arrow {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 8px;

  .svg {
    width: 100%;
  }
}

.listing-block__content {
  padding: 10px $gutter-desktop;
  overflow: auto;
  height: calc(100% - 60px);
}

.listing-block__item {
  border-bottom: 1px solid palette('grey', 'light');
  display: flex;
  justify-content: space-between;
  padding: 12px 0;

  .listing-block--ordered-list & {
    counter-increment: li;
    list-style: none;

    &::before {
      content: counter(li);
      display: inline-block;
      padding-right: $gutter-desktop;
    }
  }

  &:last-child {
    border: 0;
  }
}

.listing-block__column--full {
  flex-grow: 1;
  max-width: 50%;
  padding-right: 10px;
  width: 50%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.listing-block__column--percentage {
  min-width: 10%;
  text-align: right;
}
.listing-block__column--quantity {
  min-width: 15%;
  text-align: right;
}
.listing-block__column--total {
  min-width: 25%;
  text-align: right;
}

.listing-block__column--number-right {
  background: palette('blue', 'base');
  border-radius: 100%;
  color: #fff;
  font-family: font('source-bold');
  font-size: 0.9em;
  height: 20px;
  max-height: 20px;
  max-width: 20px;
  min-height: 20px;
  min-width: 20px;
  text-align: center;
  width: 20px;
}

.listing-block__column--first {
  max-width: 45%;
  padding-right: 10px;
  width: 45%;
}
