.order-item-description{
    display: flex;
    flex-direction: column;
    line-height: 1.4;
    align-self: center;

    .order-ordered-qty {
        font-size: 12px;
        color: palette('grey', 'semiLight');
    }
}