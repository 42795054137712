.link {
  background-color: transparent;
  color: palette('grey', 'semiDark');
  cursor: pointer;
  display: inline-block;
  outline: none;
  text-decoration: none;
  transition: color 0.3s ease;
  user-select: none;

  &:hover {
    color: palette('blue', 'dark');
  }
}

.link__icon {
  display: inline-block;
  fill: palette('grey', 'semiDark');
  margin-left: 8px;
  transition: fill 0.3s ease;
  vertical-align: middle;
  width: 18px;

  .link:hover & {
    fill: palette('blue', 'dark');
  }
}

.link__text {
  display: inline-block;
}

