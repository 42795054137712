
        @import '@/core/assets/scss/abstracts/__variables.scss';
        @import '@/core/assets/scss/abstracts/_animations.scss';
        @import '@/core/assets/scss/abstracts/_fonts.scss';
        @import '@/core/assets/scss/abstracts/_functions.scss';
        @import '@/core/assets/scss/abstracts/_mixins.scss';
        @import '@/core/assets/scss/abstracts/_placeholders.scss';
        
.badge {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: unset;
    border-radius: 50%;
    font-size: 12px;
    width: 15px;
    height: 15px;
    padding: 3px;
    background-color: #000000;
    color: #FFFFFF;
}