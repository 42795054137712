
.checkbox {
    display: flex;
    width:100%;
    height: 30px;

    .checkbox-label {
        display: flex;
        align-items: center;
        padding-left: 5px;
    }

    .checkbox-wrapper {
        display: block;
        position: relative;
        font-size: 22px;
        margin: 3px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        width: 25px;
    
        input {
            cursor: pointer;
            position: absolute;
            opacity: 0;
            height: 25px;
            width: 25px;
            left: 0;
            z-index: 2;
        }
    
        .checkbox-wrapper__checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: palette('white', 'base');
            border: 1px solid rgba(136, 136, 136, 0.315);
        }
    
        .checkbox-wrapper__checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }
    
        .checkbox-wrapper__checkmark:after {
            left: 8px;
            top: 2px;
            width: 6px;
            height: 15px;
            border: solid palette('blue', 'base');
            border-width: 0 1px 1px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    
        .notfull .checkbox-wrapper__checkmark:after {
            left: 2px !important;
            top: 6px !important;
            width: 9px !important;
            height: 1px !important;
            background-color: solid palette('blue', 'semiDark') !important;
            border-width: 0 !important;
            -webkit-transform: rotate(0deg) !important;
            -ms-transform: rotate(0deg) !important;
            transform: rotate(0deg) !important;
        }

        &.small {
            margin-top: 8px;

            input {
                height: 15px;
                width: 15px;
            }

            .checkbox-wrapper__checkmark {
                height: 15px;
                width: 15px;
            }

            .checkbox-wrapper__checkmark:after {
                left: 5px;
                top: 1px;
                width: 3px;
                height: 10px;
            }
        }
    }
    
    .checkbox-wrapper:hover input ~ .checkbox-wrapper__checkmark {
        background-color: palette('white', 'base')
    }
    
    .checkbox-wrapper input:checked ~ .checkbox-wrapper__checkmark {
        background-color: palette('white', 'base')
    }
    
    .checkbox-wrapper input:checked ~ .checkbox-wrapper__checkmark:after {
        display: block;
    }

    .checkbox-disabled, .checkbox-disabled:hover {
        .checkbox-wrapper__checkmark {
            background-color: palette('grey', 'gainsboro');
        }

        input {
            cursor: default;
        }
    }
    .checkbox-wrapper.checkbox-disabled:hover input ~ .checkbox-wrapper__checkmark {
        background-color: palette('grey', 'gainsboro');
    }
  }