.tabs {
    display: flex;    
    flex-flow: column;
    height: 100%;
    width: 100%;

    &__header {
        display: flex;
        border: 1px solid palette('blue', 'dark');
        border-radius: 7px;
        height: 30px;
        width: fit-content;
        background-color: palette('white', 'base');
        cursor: pointer;

        .tab {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 10px;
            border-radius: 5px;
            color: palette('grey', 'dark');
            font-family: 'source_sans_prosemibold';
            font-size: 14px;
            list-style-type: none;

            &--selected{
                color: palette('white', 'base');
                background-color: palette('blue', 'dark');
            }
        }
    }

    .tab__content {
        width: 100%;
        height: 100%;
    }

    .hide{
        visibility: hidden;
        height: 0px;
        display: block;
        padding-top: 0;
    }
}

