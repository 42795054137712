@keyframes productInfoSlideIn {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(140px);
  }
}

@keyframes productInfoSlideOut {
  0% {
    transform: translateX(140px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes productActionsSlideIn {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(140px);
  }
}

@keyframes productActionsSlideOut {
  0% {
    transform: translateX(140px);
  }

  100% {
    transform: translateX(0);
  }
}
