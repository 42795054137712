
        @import '@/core/assets/scss/abstracts/__variables.scss';
        @import '@/core/assets/scss/abstracts/_animations.scss';
        @import '@/core/assets/scss/abstracts/_fonts.scss';
        @import '@/core/assets/scss/abstracts/_functions.scss';
        @import '@/core/assets/scss/abstracts/_mixins.scss';
        @import '@/core/assets/scss/abstracts/_placeholders.scss';
        
.horizontal-list {
    width: 100%;
    display: flex;

    @include reverse-breakpoint($small) {
        overflow-x: auto;
        overflow-y: hidden;
        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
          display: none;
        }
        
        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
}

.horizontal-list__container  {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    
    @include reverse-breakpoint($small) {
        flex-wrap: nowrap;
    }
}

.node {
    display: flex;
    padding: 5px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
}

.btn {
    padding: 5px 8px;
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: #50C8E8;
    background-color: #FFFFFF;
    font-family: source_sans_probold;
    text-transform: uppercase;
    color: #40454c;
}

.active-node {
    .btn,
    .btn:disabled,
    .btn[disabled] {
        border-color: #FFF;
        background-color: #50C8E8;
        color: #40454c;
        margin: -1px 0;
    }
    .btn-delete {
        color: #3ca2be;
    }
}

.btn-label {
    flex-grow: 1;
}

.btn-label-can-delete {
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.btn-delete {
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-left-style: none !important;
    color: #becbcf;

    .fa-times{
        font-size: 14px;
    }
}

.btn-add {
    padding: 5px 10px;
    color: #c6d1d5;

    .fa-plus{
        font-size: 14px;
    }
}

.btn:disabled,
.btn[disabled]{
    border-color: #50c7e875;
    color: #c6d1d5;
    cursor: default;
}