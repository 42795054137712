.widget {
    .widget__body {
        .widget__content {
            flex-direction: column;
            justify-content: flex-start;

            .widget__content-table {
                width: 100%;
                border-collapse: collapse;

                .widget__content-table-row {
                    line-height: 36px;
                    border-top: 1px solid rgb(243, 242, 241);
                    padding: 10px 0;
                    font-family: 'source_sans_proregular';
                }
            }
        }
    }
}