.widget {
  .widget__header {
    .simple-widget__label {
      position: absolute;
      width: 100%;
      padding-top: 20px;
      text-align: center;
      z-index: 0;
      text-transform: uppercase;
    }
  }

  .widget__body {
    .simple-widget__tile {
      display: flex;
      position: relative;
      width: 100%;
      height: 100%;
      color: palette('blue', 'dark');

      @include reverse-breakpoint($small) {
        position: unset;
      }

      .simple-widget__content-icon {
        display: flex;
        align-items: center;
        position: absolute;
        height: 100%;
        top: 0;
        left: 5px;
        font-size: 64px;

        @include reverse-breakpoint($small) {
          align-items: flex-end;
          color: palette('grey', 'light');
          font-size: 150px;
          top: unset;
          bottom: -20px;
          left: -10px;
        }
      }

      .simple-widget__content-data {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        
        @include reverse-breakpoint($small) {
          padding-right: 0px;
        }
    
        .simple-widget__content-data-primary {
          font-size: 30px;
          z-index: 1;
        }
    
        .simple-widget__content-data-secondary {
          color: palette('grey', 'semiLight');
          font-size: 18px;
          z-index: 1;
        }
      }
    }
  }
}