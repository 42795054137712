.hierarchy-template-inside {
    display: flex;
    flex-flow: column;
    height: 100%;

    .hierarchy-template__target {
        display: flex;
        justify-content: space-between;
        width: 100%;
        
        .button-update{
            display: flex;
            align-items: center;
    
            button{
                min-height: 30px;
                height: 30px;
                font-size: 16px;
            }
        }
    }

    .active__button {
        width: 20%;
        min-width: 0;
        min-height: $button-height;
        margin-left: 10px;
        padding: 0 5px;
        fill: white;
        color: white;

        .fas {
            color: white;
            font-size: 8px;
            padding-top: 2px;
        }
        
        &.active--on{
            color: palette('grey', 'dark');
        
            path{
                fill: palette('grey', 'dark');
            }
        }
        &--text{
            font-size: 16px;
        }
    }

    .tree-grid-box {
        height: calc(100% - 62px);

        .tree-grid {
            min-height: 300px;
            height: calc(100% - #{$button-height});
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }

    .tree-grid-box-edit {
        height: calc(100% - 84px);

        .tree-grid {
            min-height: 300px;
            height: calc(100% - 84px);
            padding-top: 15px;
        }
    }

    .tree-grid-title {
        width: 100%;
    }

    .tree-grid-search {
        display: flex;
        flex-flow: row;
        height: $input-height;
    }

    .tree-grid-search .input-wrap {
        height: $input-height;
    }

    .grid-readonly {
        background-color: transparent;
        position: absolute;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
    }

    /* ----- AG-GRID OVERWRITE CSS ----- */
    .tree-grid{
        .ag-theme-balham .ag-row-selected {
            border-color: #d9dcde;
            background-color: #fcfdfe;
        }
    }
}