  .chart-block {
    position: relative;
    background-color: #fff;
    border-bottom: 2px solid palette('grey', 'semiLight');
    color: palette('grey', 'semiDark');
    display: block;
    font-family: font('source-bold');
    width: 100%;
    margin: 30px 0 0 0;
  }
  .chart{
    width:100%;
  }

  .chart__range { 
    display: flex;
    flex-flow: column;
    justify-content: center;
    visibility: hidden; /* hides sub-menu */
    opacity: 0;
    position: absolute;
    top: 100%;
    right: 0;
    width: 20%;
    transform: translateY(-2em);
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
    z-index: 1;
    background-color: white;
    border: 1px solid #efeeed;
    box-shadow: -10px 10px 20px 0px rgba(0, 0, 0, 0.1);

    @include reverse-breakpoint($xsmall) {
      width: 100%;
    }

    &.open{
      visibility: visible;
      opacity: 1;
      transform: translateY(0%);
      transition-delay: 0s, 0s, 0.3s; /* this removes the transition delay so the menu will be visible while the other styles transition */
    }

    .range{
      padding: 10px;
      display: flex;
      align-items: center;
      height: 50px;
      input{
        cursor: pointer;
        width: 33%;
      }
      label{
        cursor: pointer;
        padding-left: 10px;
      }
    }
  }
.chart-block__wrap{
  position: relative;
}
  .chart-block__label {
    @include font-smoothing();
    border-bottom: 1px solid palette('grey', 'light');
    cursor: pointer;
    display: flex;
    font-family: font('source-bold');
    height: 60px;
    justify-content: space-between;
    align-items: center;
    padding: 0 $gutter-desktop;
    text-transform: uppercase;
    transition: color 0.3s ease;
    user-select: none;
    .svg {
      fill: palette('grey', 'semiDark');
    }
  
    &:hover {
      color: palette('blue', 'dark');
  
      .svg {
        fill: palette('blue', 'dark');
      }
    }
  }
  
  .chart-block__label-arrow {
    transform: rotate(90deg);
    align-items: center;
    display: flex;
    justify-content: center;
    width: 10px;
    transition: all .3s ease;
    .svg {
      width: 100%;
    }
    &.open{
      transform: rotate(-90deg);
    }
  }
  
  .chart-block__content {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
    width: 100%;
    height: 420px;
    @include breakpoint($xsmall) {
      padding: 10px $gutter-desktop;
    }
  }
  
  .chart-block__blocks {
    display: flex;
    margin: 15px 0;
    min-width: 300px;
    width: 50%;
  }
  
  .chart-block__icon {
    align-content: flex-start;
    height: 60px;
    margin: 0 30px;
    width: 50px;
  
    @include breakpoint($xsmall) {
      width: 60px;
    }
  }
  
  .chart-block__text {
    font-family: font('source-regular');
    font-size: 0.8em;
  }
  
  .chart-block__text--uppercase {
    font-family: font('source-bold');
    text-transform: uppercase;
  }
  
  .chart-block__text--bold {
    font-family: font('source-bold');
    font-size: 2em;
    margin-top: 0;
  
    @include reverse-breakpoint($xsmall) {
      font-size: 1.2em;
      margin-top: 10px;
    }
  }
  