.summary-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 30px 0 0 0;
  padding-right: 5px;
  float: left;
  height: 570px;
  @include reverse-breakpoint($medium) {
    height: auto;
  }
}

.summary-block__blocks {
  background-color: #fff;
  border-bottom: 2px solid palette('grey', 'semiLight');
  color: palette('grey', 'semiDark');
  font-family: font('source-bold');
  min-width: 250px;
  width:100%;
  height: 100%;
  display: flex;
  flex-flow: column;
}

.summary-block__label {
  @include font-smoothing();

  border-bottom: 1px solid palette('grey', 'light');
  cursor: pointer;
  display: flex;
  font-family: font('source-bold');
  height: 60px;
  justify-content: space-between;
  line-height: 60px;
  padding: 0 $gutter-desktop;
  text-transform: uppercase;
  transition: color 0.3s ease;
  user-select: none;

  &:hover {
    color: palette('blue', 'dark');

    .svg {
      fill: palette('blue', 'dark');
    }
  }
}

.summary-block__label-arrow {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 8px;

  .svg {
    width: 100%;
  }
}

.summary-block__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px $gutter-desktop;
  width: 100%;
  height: 100%;
}

.summary-block__item {
  display: flex;
  flex-flow: wrap;
  padding: 20px;
  @include reverse-breakpoint($small) {
    display: flex;
    flex-flow: wrap;
    height: 165px;
    padding: 0;
  }
}
.summary-block__item:nth-last-child(n+3) {
  border-bottom: 2px solid #efeeed;
}
@include reverse-breakpoint($small) {
  .summary-block__item:nth-last-child(n+2) {
    border-bottom: 2px solid #efeeed;
  }
}
.summary-block__item:nth-child(odd) {
  border-right: 2px solid #efeeed;
  @include reverse-breakpoint($small) {
    border-right: none;
  }
}
.summary-block__icon {
  align-content: flex-start;
  height: 60px;
  width: 60px;
}
.summary-block__text {
  font-family: font('source-regular');
}

.summary-block__text--uppercase {
  font-family: font('source-bold');
  text-transform: uppercase;
}

.summary-block__text--bold {
  font-family: font('source-bold');
  font-size: 1.5em;
}

.summary-block__text--border-top {
  border-top: 2px solid #c6d3e0;
}

.summary-block__circle {
  fill: none;
  stroke-dasharray: 100, 100;
}
.summary-block__underValue{
  justify-content: flex-end;
  padding-right: 20px;
  color: palette('grey', 'base')
}