
        @import '@/core/assets/scss/abstracts/__variables.scss';
        @import '@/core/assets/scss/abstracts/_animations.scss';
        @import '@/core/assets/scss/abstracts/_fonts.scss';
        @import '@/core/assets/scss/abstracts/_functions.scss';
        @import '@/core/assets/scss/abstracts/_mixins.scss';
        @import '@/core/assets/scss/abstracts/_placeholders.scss';
        
.chart-config {
    display: flex;
    flex-flow: column;
    padding: 2.5px 0;

    .chart-config__span {
        font-size: 18px;
    }

    .chart-config__chart-type {
        display: flex;
        flex-flow: column;
        padding: 2.5px 0;
    }

    .chart-config__chart-options {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        padding: 10px 0;
        font-size: 15px;

        .chart-config__chart-options-curve {
            display: flex;
        }

        .chart-config__chart-options-bars {
            display: flex;
        }
    }

    .chart-config__axe-x {
        display: flex;
        flex-flow: column;

        .chart-config__column {
            display: flex;
            flex-flow: column;
            padding: 2.5px 0;

            .option__model{
                font-size: 14px;
                font-weight: bold;
            }
            
            .option__icon{
                font-size: 12px;
                padding: 0 10px;
            }
            
            .option__description{
                font-size: 14px;
            }
        }

        .chart-config__interval-options {
            display: flex;
            flex-flow: row;
            padding: 2.5px 0;

            .chart-config__interval {
                display: flex;
                flex-flow: column;
                width: 100%;

                @include breakpoint ($xxsmall) {
                    padding-left: 15px;

                    @include breakpoint ($large) {
                        padding-left: 50px;
                    }
                }
                
                @include reverse-breakpoint ($xxsmall) {
                    padding-left: 15px;
                }
            }

            .chart-config__interval-min {
                display: flex;
                flex-flow: column;
                padding-left: 5px;
            }

            .chart-config__interval-max {
                display: flex;
                flex-flow: column;
                padding-left: 5px;
            }
        }
    }

    .chart-config__axe-y {
        display: flex;
        flex-flow: column;
        padding: 2.5px 0;
    }
}