
.product-costs__nocosts{        
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
}

.product-costs__body{
    width: 100%;
    display: flex;
    flex-flow: column;
    min-height: 150px;
    height: $product-body-costs-height;
    padding-top: 30px;
    padding-bottom: 10px;

    @include reverse-breakpoint($medium) {    
        min-height: unset;
        height: unset;     
        padding-top: 10px;
        padding-bottom: 10px;
        flex-grow: 1;
    }

    input {
        color: palette('grey', 'semiDark');
        border: 1px palette('grey', 'semiLight') solid;
        border-radius: 6px;
        height: 35px;
        padding: 5px;
        text-align: right;

        @include reverse-breakpoint($small) {
            font-size: 16px;
        }

        :disabled {
            background-color: #c5c5c5;
        }
    }

    .product-costs__column-headers{
        width: 100%;
        display: flex;
        color: palette('grey', 'semiLight');
        font-family: 'source_sans_prosemibold';
        font-size: 14px;
        padding-bottom: 10px;

        @include reverse-breakpoint($small) {
            font-size: 12px;
        }

        span{
            text-align: center;
            flex: 1 1 0;

            
            &:first-child {
                text-align: left;
            }
            &:last-child {
                text-align: right;
            }
        }
    }

    .product-costs__rows{
        overflow: auto;
        padding-right: 5px;
        width: calc(100% + 10px);
        scrollbar-gutter: stable;
        display: flex;
        flex-flow: wrap;

        .product-costs__row{
            display: flex;
            flex-flow: row;             
            width: 100%;
            height: 70px;
            align-items: center;
            font-size: 20px;

            @include reverse-breakpoint($medium) {
                line-height: 1;
            }  
            
            .product-costs__element{
                display: flex;
                flex-flow: column;
                align-items: center;
                text-align: center;
                flex: 1 1 0;
                position: relative;
                padding: 0 5px;

                &:first-child {
                    align-items: start;
                    text-align: left;
                    padding-left: 0;
                }
                &:last-child {
                    align-items: end;
                    text-align: right;
                    padding-right: 0;
                    .input-wrap--number{
                        text-align: right;
                    }
                }

                .input-wrap--number{
                    display: inline-block;
                    text-align: center;
                    width: 100px;
                }

                .submeasure {
                    font-size: 14px;
                    font-family: font('source-semi-bold');
                    padding-left: 10px;
                }
            }
        }
    }
}

