.receiving-product {
    display: flex;
    flex-flow: column;
    height: 100%;
    
    .template-body {

        .receiving-product__info-block {
            display: flex;
            justify-content: space-between;
            width: 100%;
            max-height: 150px;
            padding-bottom: 30px;

            .info-block__product-info {
                display: flex;
                flex-flow: column;
                width: 60%;

                span {
                    font-size: 20px;
                }

                .info-block__product-info_description {
                    font-family: font('source-semi-bold');
                    color: palette('blue', 'base');
                }

                .info-block__product-info_upc {
                    font-family: font('source-regular');
                    color: palette('grey', 'semiLight');
                }
            }

            .info-block__orders-info {
                display: flex;
                flex-flow: column;
                align-items: center;
                width: 40%;
                margin-top: 20px;
                overflow: auto;

                span {
                    font-family: font('sans-serif');
                    color: palette('grey', 'base');
                    font-size: 12px;
                    font-weight: 700;
                }
            }
        }

        .product-costs__body{
            height: 100%;

            .product-costs__row{
                height: 100px;
    
                .product-costs__element{
                    
                    .miss-match-value {
                        color: crimson;
                    }
                    
                    .toggle {
                        display: flex;
                        align-items: center;
                        height: 35px;
                    
                        .toggle-switch input:checked + .toggle-slider__round {
                            border-color: palette('green', 'base');
                        }
                        .toggle-switch input:checked + .toggle-slider__round:before {
                            background-color: palette('green', 'base');
                        }
                    }

                    .current-value{
                        padding-bottom: 20px;
                    }

                    .input-wrap--number{
                        max-width: 90px;
                    }

                    .submeasure{
                        position: absolute;
                        top: 25px;
                    }
                }
            }
        }
    }
}