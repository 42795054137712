
        @import '@/core/assets/scss/abstracts/__variables.scss';
        @import '@/core/assets/scss/abstracts/_animations.scss';
        @import '@/core/assets/scss/abstracts/_fonts.scss';
        @import '@/core/assets/scss/abstracts/_functions.scss';
        @import '@/core/assets/scss/abstracts/_mixins.scss';
        @import '@/core/assets/scss/abstracts/_placeholders.scss';
        
.address{
    height: 100%;
    display: flex;
    flex-flow: column;
  
    .template-body{  
  
      .address__streetNumber, .address__country, .address__town{
        width: 55%;
        padding: 0 5px 0 0;
      }
      
      .address__apartment, .address__state, .address__zipCode{
        width: 45%;
        padding: 0 0 0 5px;
      }
    }
  }