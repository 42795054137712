input{
  width: 100%;
  height:100%;
  &:focus{
    outline: none;
  }
}

.input-wrap {
  background-color: #fff;
  color: palette('grey', 'semiDark');
  border: 1px solid rgba(136, 136, 136, 0.315);
  border-radius: 6px;
  display: flex;
  font-family: font('source-regular');
  height: $input-height;
  outline: none;
  padding: 0 20px;
  width: 100%;
  align-items: center;

  &--number{
    @extend .input-wrap;
    text-align: right;    
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type=number] {
      -moz-appearance: textfield;
    }
  }
     
  &--remove-background{
    @extend .input-wrap;
    padding: 0;
    background-color: unset;
    border: unset;
    
    .multiselect__tags{
      background: unset;
      .multiselect__spinner{
        background: unset;
      }
      .multiselect__input{
        background: unset;
      }
      .multiselect__single{
        background: unset;
      }
    }
    .multiselect__content-wrapper{
      border-top: 1px solid #e0e0e0;
    }
  }

  &.input-wrap--multiselect-margin{
    .multiselect__content-wrapper{
      min-width: calc(100% + 40px);
      width: auto;
      margin-left: -20px;
    }
  }

  &.input-wrap--multi{
    min-height: $input-height;
    height:fit-content;
    .multiselect--active:not(.multiselect--above){
      .multiselect__input{
        margin-bottom: 5px;
      }
    }
  }
  
  &.disabled{
    background-color: palette('grey', 'gainsboro');

    /*multi-select overides*/
    .multiselect--disabled{
      color: palette('grey', 'semiLight');
      background-color: palette('grey', 'gainsboro');
    }
    .multiselect--disabled .multiselect__select{
      background-color: palette('grey', 'gainsboro');
      &:before{
        content: none;
      }
    }
    .multiselect__tags{
      background-color: palette('grey', 'gainsboro');
      .multiselect__tag{
        opacity: 0.8;
      }
    }
    .multiselect__single{
      background-color: palette('grey', 'gainsboro');
    }
    .multiselect--disabled{
      opacity: 1;
    }
    /*multi-select overides*/
  }
  
  &:focus-within {
    border: 1px solid palette('blue', 'base');

    &.input-wrap--remove-background{
      border: unset;
    }
  }
  .input-formatter{
    padding: 0 5px;
  }
  ::placeholder{
    font-size: 16px;
    opacity: 0.5;
  }
  &.is-invalid{
    border-color: red;
  }

  select{
    outline: none;
    width:100%;
  }

  .multiselect{
    cursor: pointer;
    min-width: 0;

    .multiselect__select{
      height: 100%;
      width: 20px;
      right: 5px;
      top: 0px;
      padding: 0;
      display: flex;
      flex-flow: wrap;
      justify-content: center;
      align-items: center;
      &:before{
        top: unset;
        margin: unset;
      }
    }
    .multiselect__tag{
      background-color: palette('blue', 'semiDark');
      margin:5px;
      .multiselect__tag-icon:hover{
        background-color: palette('blue', 'semiDark');
      }
      .multiselect__tag-icon:focus{
        background-color: palette('blue', 'semiDark');
      }
      .multiselect__tag-icon:after{
        color: palette('blue', 'dark');
      }
    }
    .multiselect__tags-wrap{
      display: flex;
      flex-flow: wrap;
      height: 100%;
    }
    .multiselect__tags{
      display: flex;
      flex-flow: wrap;      
      align-items: center;
      padding: 0;
      border: none;
      font-size: inherit;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100% - 20px);
      height: 100%;
      .multiselect__placeholder{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        padding: 0;
      }
    }
    .multiselect__option--highlight{
      background-color: rgba(204, 204, 204, 0.25);
      color: unset;
    }
    .multiselect__input, .multiselect__single{
      display: flex;
      align-items: center;
      min-height: unset;
      line-height: normal;
      padding: 0;
      margin-bottom: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .multiselect__option--selected{
      color: inherit;
      font-weight: inherit;
    }
    .multiselect__element{
      .multiselect__option--group{
        padding-left: 20px !important;
        font-weight: 700;
      }
      .multiselect__option--disabled{
        background: inherit !important;
        color: inherit !important;
        cursor: inherit;
        pointer-events: inherit;
      }
      .multiselect__option{
        padding-left: 30px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  .multiselect--dynamic {
    display: flex;
    flex-flow: row-reverse;
    .multiselect__select{
      position: relative;
      height: unset;
      padding: unset;
    }
    .multiselect__tags{
      width: 100%;
    }
    .multiselect__content-wrapper{
      top: 100%;
      width: auto;
      left: 0;
    }
  }
  
  .multiselect--disabled {
    &.multiselect--disabled__remove-style {
      background: inherit;
      opacity: inherit;
    }
    &.multiselect--disabled__remove-select {
      .multiselect__select{
        display: none;
      }
    }
  }
}

.input-wrap-checkbox {
  display: flex;
  width: 100%;
  height: $input-height;

  &.disabled > .container{
    cursor: default;
  }

  .container {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 100%;
  }
  
  .container input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    height: 32px;
    width: 32px;
  }
  
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 32px;
    width: 32px;
    border-radius: 6px;
    background-color: palette('white', 'base');
    box-shadow: 0px 3px 5px 2px #8189937d;

    &.disabled{
      cursor: default;
      background-color: palette('grey', 'gainsboro');
    }
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  .container .checkmark.disabled:after{
    border-color: palette('grey', 'semiLight')
  }

  .container .checkmark:after {
    left: 12px;
    top: 7px;
    width: 6px;
    height: 12px;
    border-color: palette('grey', 'base');
    border-style: solid;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}


.input-wrap-radio {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: $input-height;

  &.disabled > .container,
  &.disabled > .container > .radio {
    cursor: default;

    .radio {
      background-color: palette('grey', 'gainsboro');
    }
  }

  .container {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 23px;
    width: 23px;
  }
  
  .container input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    height: 23px;
    width: 23px;
  }
  
  .radio {
    position: absolute;
    top: 0;
    left: 0;
    height: 23px;
    width: 23px;
    border-radius: 12px;
    background-color: palette('white', 'base');
    border: 1px solid rgba(136, 136, 136, 0.35);
    z-index: 2;
  }

  .radio:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .container input:checked ~ .radio:after {
    display: block;
  }
  
  .container .radio.disabled:after{
    border-color: palette('grey', 'semiLight')
  }

  .container .radio:after {
    left: 6px;
    top: 6px;
    width: 9px;
    height: 9px;
    background-color: palette('grey', 'semiDark');
    border-radius: 8px;
    border-width: 0px;
  }

  .radio-label {
    position: absolute;
    top: 1px;
    padding-left: 36px;
    width: max-content;
    font-size: 15px;
    z-index: 1;
  }

  .radio-label + label {
    position: absolute;
    top: 20px;
    padding-left: 36px;
    width: max-content;
    font-size: 12px;
    z-index: 1;
  }
}

.input-wrap:disabled, .input-wrap > input:disabled {
  color: palette('grey', 'semiLight');
  background-color: palette('grey', 'gainsboro');

  .multiselect__tags {
    background-color: palette('grey', 'gainsboro');
  }
}

.input-required{
  padding: 0 0 0 10px;
  height: 100%;
  color: red;
}
.input-error{
  padding: 0 0 0 10px;
  font-size: 12px;
  color: red;
}

textarea.input-wrap{
  height: 100%;
  padding: 10px 20px;
  resize: none;
}