.label-printer{
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    .label-heading__right{
        display: flex;
        justify-content: flex-end;
        align-items: end;
        width: 100%;
        .subdivision {
            margin: 0 5px;
            display: flex;
            flex-flow: column;
        }
        .label{
            margin-bottom: 5px;
        }

        .label-type-select {
            @extend .subdivision;
            height: 100%;
            width: 155px;
            select {
                width: 100%;
                height: 42px;
                padding-left: 5px;
                border-radius: 6px;
                border: solid 0.5px #c3c2c2b0;
            }
            .label-icon{
                display: flex;
                flex-direction: row;
                .tooltip{
                    position: relative;
                    i{
                        color: #13dcff;
                        margin: 4px 0 0 10px
                    }
                    .info{
                        position: absolute;
                        z-index: 20;
                        box-sizing: border-box;
                        background-color: #fff;
                        display: none;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        width: 380px;
                        padding: 20px 20px 10px;
                        border: solid 0.5px #83818144;
                        box-shadow: -0.5px 0.5px 6px -1px rgb(124, 129, 129);

                        .message{
                            box-sizing: border-box;
                            padding-left: 20px;
                            text-wrap: balance;
                            font-size: 1.7rem;
                        }
                    }
                    .show-info{
                        display: flex;
                    }
                }
            }
        }
        .label-item-quantity{
            @extend .subdivision;
            width: 150px;
        }

    }
    .last-print-info {
        padding: 20px 0;
        display: flex;
        justify-content: flex-end;
        .date {
            margin-left: 5px;
        }
        .user-print {
            margin-left: 7px;
        }
    }

    .bo-grid{
        width: 100%;
        height: auto;
        flex: 1 1 auto;
        padding-top: 10px;
    }
}

