.product-info-header{
    display: flex;
    flex-flow: column;
    width: 100%;
    height: $product-info-header-height;
    padding-bottom: 30px;

    .product-info-header__description{
        padding-bottom: 10px;

        span{
            font-size: 20px;
            font-weight: bold;
        }
    }

    .product-info-header__details{
        display: flex;
        flex-flow: wrap;
        position: relative;
        height: 100%;

        .detail{
            display: flex;
            align-items: center;
            height: 33.33%;
        }

        .product-info-header__details-left{
            display: flex;
            flex-flow: column;
            height: 100%;
            width: 50%;
            padding-left: 20px;
            padding-right: 5px;

            .upc{
                font-family: 'source_sans_prosemibold';
            }
        }
        
        .separator{
            position: absolute;
            height: 90%;
            top: 5%;
            left: 50%;
            border: 1px solid #bdc3c7;
            border-radius: 5px;
        }

        .product-info-header__details-right{
            display: flex;
            flex-flow: column;
            height: 100%;
            width: 50%;
            padding-left: 30px;

            .sdp{                        
                padding-left: 15px;
                i{
                    transform: rotate(90deg);
                }
                .sdpCode{
                    padding-left: 10px;
                }
            }
            .inventory{
                white-space: pre;
                font-family: 'source_sans_prosemibold';
            }
            .avg{
                white-space: pre;
                display: flex;
                align-items: center;
            }
        }
    }
}
