.filter-input{

    .input-wrap{
        height: inherit;
        min-height: 40px;
        padding: 0px;
    }

    &.is-invalid{
        border: none;
        margin-bottom: 20px;
    }

    .input-error{
        position: absolute;
        height: 15px;
    }
    
    .filter-operator{
        flex-shrink: 0;
        min-height: 40px;
        min-width: 40px;

        .filter-operator__value{
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;

            .filter-operator__value-icon{
                font-size: 12px;
            }
            .filter-operator__value-description{
                white-space: nowrap;
                font-size: 10px;
                font-weight: bold;
            }
        }

        .filter-operator__dropdown{
            width: 100px;
            position: absolute;
            left: 0;
            top: 40px;
            background-color: palette('white', 'base');
            border: 1px solid rgba(136, 136, 136, 0.315);
            border-radius: 7px;
            z-index: 1;

            .dropdown-content__element{                    
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                height: 30px;
                list-style: none;
                cursor: pointer;
            }
            .dropdown-content__element:hover{                    
                background-color: rgba(204, 204, 204, 0.25);
            }
        }
    }

    .filter__value{
        width: 100%;
        min-height: 40px;
        border-left: 1px solid rgba(136, 136, 136, 0.315);

        .filter__value-group {
            height: 100%;
            display: flex;
            flex-direction: column;

            .filter-multi {
                flex-grow: 1;
                min-height: 32px;
                border-bottom: 1px solid rgba(136, 136, 136, 0.315);
                padding: 5px;

                .horizontal-list {
                    overflow-x: inherit;
                    overflow-y: inherit;

                    .horizontal-list__container {
                        display: flex;
                        flex-wrap: wrap;

                        .node {
                            padding-right: 2px;
                            padding-bottom: 2px;

                            .btn {
                                padding: 3px 6px;
                                border-radius: 13px;
                                border-width: 1px;
                                border-color: #FFFFFF;
                                background-color: #40454C;
                                color: #FFFFFF;
                                cursor: default;
                                text-transform: inherit;
                                font-family: inherit;
                            }

                            .btn-delete {
                                border-left: none;
                                padding-right: 8px;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            .filter__value-text, .filter__value-integer, .filter__value-numeric {
                height: 40px;
                background-color: inherit;
                padding: 5px 10px 5px 20px;
            }

            .filter__value-boolean {
                padding-left: 20px;
                width: 95%;

                .multiselect__tags {
                    border: none;
                }
            }

            .filter__value-date {
                border: none;
                height: 100%;
                width: 99%;

                .icon {
                    width: 35px;
                }
            }
        }
    }
}