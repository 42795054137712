.menu-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  #detailMenuRenderer__menu{
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
}
.detail-menu-content {
  position: absolute;
  top: 40px;
  right: 55px;
  background-color: #f5f7f7;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  color: black;
  line-height: 3;
  border-radius: 5px;
  border: 1px solid;
  border-color: #efeeed;

  &.open-top {
    top: -70px;
    right: 55px;
  }

  .detail-menu-element:not(:last-child) {
    border-bottom: 1px solid #efeeed;
  }

  .detail-menu-element {
    display: flex;
    flex-flow: wrap;
    width: 160px;
    cursor: pointer;

    .element-button {
      display: flex;
      flex-flow: wrap;
      align-items: center;
      width: 100%;
      
      .element-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        width: 20px;
        margin: 0 10px;
      }
    }
  }
  :hover {
    background-color: palette("grey", "light");
  }
}
