@font-face {
  font-family: 'source_sans_prolight';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url('@/fonts/sourcesanspro-light-webfont.woff2') format('woff2');
}

@font-face {
  font-family: 'source_sans_proregular';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url('@/fonts/sourcesanspro-regular-webfont.woff2') format('woff2');
}

@font-face {
  font-family: 'source_sans_prosemibold';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url('@/fonts/sourcesanspro-semibold-webfont.woff2') format('woff2');
}

@font-face {
  font-family: 'source_sans_probold';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url('@/fonts/sourcesanspro-bold-webfont.woff2') format('woff2');
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('@/fonts/HelveticaNeue.woff') format('woff');
}