.inventory-adjustment{
    display: flex;
    flex-flow: column;
    height: 100%;
    
    .inventory-adjustment__comment{
        flex-grow: 1;
        min-height: 150px;
        max-height: 200px;
        
        textarea{
            padding: 10px 20px;
            resize: none;
        }
    }

    .selected__reason-code{
        font-weight: bold;
    }

    .selected__chevron{
        font-size: 11px;
        padding: 0 5px;
    }
}

