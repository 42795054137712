.promotion-item-add-template-inside{
  label{
    display: flex;
    align-items: center;
    font-family: 'HelveticaNeue';
    letter-spacing: 0.5px;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  .promo-details{
    display: flex;
    width: 95%;
    margin:30px 0 25px 0;
    
    .discount-type{
      width: 40%;
      input{
        border-radius: 6px 0 0 6px;
      }
    }
    .discount-type-value{
      width: 60%;
      input{
        border-radius: 0 6px 6px 0;
      }
    }
  }
  .discount-maximum{
    display: flex;
    flex-direction: column;
    width: 95%;
    margin:0 0 25px 0;
  }
  .product-prices{
    display: flex;
    width: 95%;
    justify-content: space-around;
    .product-price-item{
      width: 30%;
      .label{
        display: block;
        font-family: 'HelveticaNeue';
        font-size: 12px;
        margin-bottom: 8px;
      }
      .price-value{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 33px;
        border: solid 1px palette('grey', 'dark');
        border-radius: 6px;
        font-weight: bold;
        font-size: 19px;
        letter-spacing: 0.5px;
        &.last{
          color: palette('red','base');
        }
      }
      
    }
  }

  .unitOfMeasurement{
    color: #40454c;
  }
}