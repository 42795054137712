.label-subNav{
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.label-heading__right{
    display: flex;
    justify-content: flex-end
}

.label-buttons{
    display: flex;
}

.bo-grid{
    height: auto;
    flex: 1 1 auto;
    padding-top: 10px;
}