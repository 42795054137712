.dashboard-management{
    display: flex;
    flex-flow: column;
    height: 100%;

    .template-body{
        justify-content: flex-start;
        flex-direction: column;

        .dashboard-management-body__info-block{
            display: flex;
            flex-flow: column;
            width: 100%;
            padding: 10px 0;
        }
    }
}