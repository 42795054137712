.dynamic-dashboard{
    height: 100%;
    width: 100%;

    .dynamic-dashboard__widget{
        height: 100%;
        width: 100%;
    }
    
    .dynamic-dashboard-grid{
        margin: -10px;

        @include reverse-breakpoint($small) {
            margin: 0 -10px;
        }
    }
    
    .vue-grid-item.cssTransforms{
        cursor: default !important;
    }
}