.user{
  height: 100%;
  display: flex;
  flex-flow: column;

  .template-body{  

    .user__language{
      width: 30%;
      padding: 0 5px 0 0;
    }
    
    .user__phone{
      width: 70%;
      padding: 0 0 0 5px;
    }
  }
}