.dashboard-content {
  height: 100%;
  width: 100%;
}

.dashboard-tabs__button {
    color: palette('grey', 'semiLight');
    cursor: pointer;
    display: inline-block;
    list-style: none;
    margin-right: 20px;
    padding: calc($gutter-desktop / 2) 0;
}

.dashboard-tabs__button--active {
    border-bottom: 3px solid palette('blue');
    font-family: font('source-semi-bold');
}

.dashboard-tabs__tab {
    display: none;
    &--active {
      display: block;
    }
}

.h-nav{
    display: inline-block;
    color: #40454c;
    font-family: font('source-bold');
  }
  
  .h-nav--current{
    background-color: rgb(80, 200, 232);
  }
  .h-nav > li{
    float:left;
  }
  .li-wrap > a {
    border-color: rgb(80, 200, 232);
    border: none;
    color: #40454c;
    padding: 5px;
    cursor: pointer;
  }
  .li-wrap{
    height: 70%;
    margin: 2px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 1px solid;
    border-color: rgb(80, 200, 232);
  }
  .border-b{
    border-bottom: 2px solid #efeeed;
  }
  
  .font-medium{
    font-size: 1em;
  }
  .flex-mobile{
    display: flex;
    align-items: center;
    width:100%;
  }
  .align-center{
    align-items: center;
  }
  .flex{
    display: flex;
  }
  .flex-wrap{
    display: flex;
    flex-wrap: wrap;
  }
  .flex-c{
    flex-flow: column;
  }
  .justify-center{
    justify-content: center;
  }
  
  .rmv-margin{
    margin: 0;
  }