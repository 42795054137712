.direct-receiving {

    .receive-button {
        margin: 10px;
    }

    .ag-theme-material {
        .ag-cell {
            .container.second-info-displayed {

                .input-wrap--number,
                .second-info {
                    @include reverse-breakpoint($small) {
                        text-align: center;
                        justify-content: center;
                        width: 65px;
                    }
                }
            }

            .detail-wrap .detail-container {
                @include reverse-breakpoint($small) {
                    padding: 5px 0;
                }
            }
        }

        .ag-cell.expend-cell {
            @include reverse-breakpoint($small) {
                padding-right: 0;
            }
        }
    }

    .grid-wrap .toggle .toggle-switch input:checked+.toggle-slider__round {
        border-color: palette('green', 'base');
    }

    .grid-wrap .toggle .toggle-switch input:checked+.toggle-slider__round:before {
        background-color: palette('green', 'base');
    }

    .grid-wrap .input-wrap {
        height: 20px;
        width: 50px;
    }
}