
        @import '@/core/assets/scss/abstracts/__variables.scss';
        @import '@/core/assets/scss/abstracts/_animations.scss';
        @import '@/core/assets/scss/abstracts/_fonts.scss';
        @import '@/core/assets/scss/abstracts/_functions.scss';
        @import '@/core/assets/scss/abstracts/_mixins.scss';
        @import '@/core/assets/scss/abstracts/_placeholders.scss';
        
$picker-header-height: 50px;

.column-picker{
  display: flex;
  width: 100%;
  height: 100%;
  @include reverse-breakpoint($medium) {
    flex-flow: wrap;
  }
  .column-picker__left{
    width: 50%;
    padding-right: 10px;
    @include reverse-breakpoint($medium) {
      width: 100%;
      padding-right: 0;
    }
  }
  .column-picker__right{
    width: 50%;
    padding-left: 10px;
    @include reverse-breakpoint($medium) {
      width: 100%;
      padding-left: 0;
    }
  }
}

.flex-c{
  display: flex;
  flex-flow: column;
}

.hide-column{
  display: none;
}

.picker-row:not(.selected__col):hover {
  background-color: #bfbfbf;
}
.picker-row:hover{
  cursor: pointer;
  .hover-show{
    display: block;
    visibility: visible;
  }
}

.button-wrapper {
  margin: 0 15px;
  display: flex;
  flex-flow: wrap;
  justify-content: flex-end;
  .option__button {
    font-size: 0.8em;
    min-height: 30px;
    height: 30px;
  }
}


.search-wrapper {
  margin: 0 15px;

  .input-wrap {
    height: 30px;

    input {
      height: 90%;
      width: 95%;
      position: relative;
      top: 1px;
    }
  }
}


.picker-content {
  height: calc(100% - #{$picker-header-height});
  overflow: auto;

  .selected__col{
    background-color: #00bcd44d;
    .hover-show{
      visibility: visible;
      display: block;
      margin-right: 8px;
      border-radius: 5px;
      min-height: 10px;
      right: 0;
      bottom: 0;
      height: 25px;
      width: 25px;
      font-size: 15px;
      font-weight: 600;
      font-family: cursive;
      text-align: center;
      justify-content: center;
    }
    .selected__col-dropdown{
      width: 100%;
      select{
        text-align-last: center;
        width: 100%;
        height: 70%;
      }
    }
  }
  .col__aggregate{
    padding: 0px 10px;
    width: 200px;
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
  }
  .col__options{
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .no-child__col {
    font-weight: 600;

    &--crop{
      font-weight: 600;
      padding: 5px 0 5px 5px;
    }

    select {
      font-weight: initial;
    }

    div {
      font-weight: initial;
    }
  }
  .selected {
    border: 2px solid rgb(239, 238, 237);
    border-radius: 7px;
    background-color: #efeeed;
    padding: 3px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .desc-nowrap {
    white-space: nowrap;
  }

  ul{
    height: 100%;
    width: 100%;
  }

  li {
    .hover-show{
      visibility: hidden;
      margin-right: 8px;
      border-radius: 5px;
      min-height: 10px;
      right: 0;
      bottom: 0;
      height: 25px;
      width: 25px;
      font-size: 15px;
      font-weight: 600;
      font-family: cursive;
      text-align: center;
      justify-content: center;
    }
    div {
      user-select: none;
      display: flex;
      align-items: center;
    }
  }
  li:not(.selected__col):hover {
    cursor: pointer;
    background-color: #efeeed;
  }
  ._col:not(.selected__col):hover {
    background-color: #bfbfbf;
  }
  ._col:hover {
    .hover-show{
      display: block;
      visibility: visible;
    }
  }
  li:hover{
    cursor: pointer;
      .hover-show._nest{
        display: block;
        visibility: visible;
      }
  }
}

.picker-header {
  min-height: $picker-header-height;
  background-color: #efeeed;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 5px;
}


.picker-list-choice {
  height: calc(100% - #{$span-height});
  border: 2px solid rgba(136, 136, 136, 0.315);
  border-radius: 7px;
  background-color: #fff;
  display: flex;
  flex-flow: column;

  @include reverse-breakpoint($medium) {
    min-height: 150px;
    max-height: 400px;
  }
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}


.relative{
  position: relative;
}


.option__button {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.8em;
  height: 50px;
  margin: 0 0 0 15px;
  min-height: 50px;
}

.picker-list-picked {
  height: calc(100% - #{$span-height});
  border: 2px solid rgba(136, 136, 136, 0.315);
  border-radius: 7px;
  background-color: #fff;
  @include reverse-breakpoint($medium) {
    min-height: 150px;
    max-height: 400px;
  }
}