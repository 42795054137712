.bo-main-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;

  .head-action-grouping{
    display: flex;
    align-items: center;
  }
}

.layout--bo {
  background-color: #efeeed;

  .span-wrap{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: source_sans_prosemibold;
    font-size: 14px;
    height: $span-height;
    line-height: 3;
    color: #40454c;
    -ms-flex-flow: wrap;
    flex-flow: wrap;
  }
}

.body-content {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
  height: calc(100% - $header-desktop-height);
  padding-right: $gutter-desktop;
  padding-bottom: $gutter-desktop;
  padding-left: calc(80px + #{$gutter-desktop});

  
  @include breakpoint($xxlarge) {      
    padding-top: $header-desktop-height;
    padding-right: calc(#{$side-nav-desktop-open-width} - #{$side-nav-desktop-closed-width} + 15px);
    padding-bottom: $gutter-desktop;
    padding-left: calc(#{$side-nav-desktop-open-width} + 15px);
  }      
  
  @include reverse-breakpoint($small) {
    padding: $header-desktop-height 10px 10px 10px;
  }
  
}

[v-cloak] {
  display: none;
}

.bo-grid{
  display: flex;
  flex-flow: column;
  height: 100%;

  @include reverse-breakpoint($medium) {
    min-height: 500px;
  }

  .bo-grid__header{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    
    .bo-grid__title{
      display: flex;
      height: $button-height;
      align-items: end;
      font-size: 20px;
      font-family: 'source_sans_prolight';
      @include reverse-breakpoint($medium) {
        font-size: 16px;
      }
    }
  }
  
  .span-value{
    font-weight: bold;
  }
}