
        @import '@/core/assets/scss/abstracts/__variables.scss';
        @import '@/core/assets/scss/abstracts/_animations.scss';
        @import '@/core/assets/scss/abstracts/_fonts.scss';
        @import '@/core/assets/scss/abstracts/_functions.scss';
        @import '@/core/assets/scss/abstracts/_mixins.scss';
        @import '@/core/assets/scss/abstracts/_placeholders.scss';
        
.model-column-picker{
    width: 100%;

    .option__model{
        font-size: 14px;
        font-weight: bold;
    }
    
    .option__icon{
        font-size: 12px;
        padding: 0 10px;
    }
    
    .option__description{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
    }
}