.scan {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;

    .scan-body__scan {
        display: flex;
        flex-flow: column;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        color: palette('blue', 'dark');
        position: relative;

        .scan-body__scan-icon {
            width: 100%;
            height: 100%;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            i {                
                font-size: 125px;

                @include reverse-breakpoint($small) {
                    font-size: 75px;
                }
            }
            span {
                font-family: 'source_sans_proregular';
                font-size: 40px;
                width: 50%;
                text-align: center;
                padding-top: 20px;
                
                @include reverse-breakpoint($small) {
                    font-size: 25px;
                }
            }  
        }

        .scan-body__scan-input {
            position: absolute;
            color:palette('blue', 'dark');
            font-size: 40px;
            font-family: 'source_sans_proregular';
            background: transparent;
            outline: none;
            caret-color: transparent;
            border: none;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}