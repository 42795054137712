.notification-banner {
    width: 400px;
    padding: 15px 20px;
    background-color: #2c2f34;
    border: 1px #1c1e21;
    border-radius: 5px;
    text-align: center;
    color: #ccc;
    margin: 5px 0px; 

    .notification-banner__action {
        padding-top: 10px;
    
        a {
          color: #4b9ad8;
          font-weight: 600;
          font-size: large;
          cursor: pointer;
        }
    }
}