.calendar-container{
  .field-input{
    height: 39px;
    min-height: 39px;
  }
}

.calendar-nav{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 20px 5px 5px;
}
.calendar__label-arrow {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 20px;
  height: 100%;
  cursor: pointer;
  &:hover{
    color: palette('blue', 'dark');
  }
  font-size: 12px;
}

.calendar-container {
  display: flex;
  background-color: palette('white');
  border: 1px solid rgba(136, 136, 136, 0.315);
  border-radius: 7px;
  height: $input-height;
  &:focus-within {
    border: 1px solid palette('blue', 'base');
    border-radius: 7px;
  }
  @include reverse-breakpoint($xsmall){
    width:100%;
  }
}

.icon {
  border-radius: 7px 0 0 7px;
  color: palette('grey', 'semiLight');
  display: flex;
  align-items: center;
  padding-left: 20px;

  &.disabled{
    color:palette('grey', 'light');
  }
}

.date-time-picker .field .field-input{
  border: none !important;
}

.datepicker-buttons-container .datepicker-button.now .datepicker-button-content{
  color: palette('blue','semiDark') !important;
}
.datepicker-buttons-container .datepicker-button.now .datepicker-button-effect{
  background: palette('blue','semiDark') !important;
}
.datepicker-buttons-container .datepicker-button .datepicker-button-effect{
  background: palette('blue','semiDark') !important;
}
.datepicker-buttons-container .datepicker-button svg{
  fill: palette('blue','semiDark') !important;
}

.datetimepicker .datepicker{
  max-width: 300px !important;
  border-radius: 7px !important;
}