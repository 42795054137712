.widget {
  @include font-smoothing();
  display: flex;
  flex-flow: column;
  background-color: #fff;
  color: palette('grey', 'semiDark');
  font-family: font('source-bold');
  width: 100%;
  height: 100%;

  .widget__header {
    display: flex;
    flex-flow: row;
    width: 100%;

    .widget__label-tabs {
      width: calc(100% - 25px);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 12px 10px 0px $gutter-desktop;
    }

    .widget__label {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 15px $gutter-desktop 0 $gutter-desktop;
      text-transform: uppercase;
      user-select: none;
    }
      
    .widget__label-cog {
      position: absolute;
      top: 0;
      right: 0;
      justify-content: flex-end;
      z-index: 1;
      cursor: pointer;
      width: 25px;
      padding-right: 5px;
      padding-top: 2px;
      font-size: 20px;
      color: palette('grey', 'semiDark');
      transition: color 0.3s ease;

      &:hover {
        color: palette('blue', 'semiDark');
      }
    }  
  } 
  
  .widget__body {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 10px;
    overflow-y: auto;
  
    .widget__content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      
      .widget__nocontent {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
  
        .warning-icon {
          cursor: pointer;
        }
      }
    }
  }
}