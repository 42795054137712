.bool-wrap{
    height: 100%;
    width: 100%;
    span{
        display: flex;
        height: 100%;
        width: 100%;
        flex-flow: wrap;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
    }
}
