.multi-type-input {

  .multi-type-input__labels {
    display: flex;
    padding-bottom: 10px;
    font-size: 12px;
    font-weight: 700;
    font-family: font('sans-serif');
    color: #40454c;
  }
  .input-wrap {
    padding-right: 0;
  }
  .multi-type-input__type {
    width: calc(70% - 5px);
  }

  .value-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 100%;
    border-left: 2px solid #e8e8e8;

    input{
      padding-left: 20px;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &[type=number] {
        -moz-appearance: textfield;
      }
      &:disabled{
        color: palette('grey', 'semiLight');
        background-color: palette('grey', 'gainsboro')
      }
    }
  }
  
  .type-wrap {
    position: relative;
    display: flex;
    flex-flow: wrap;
    width: 70%;
    height: 100%;
    border: none;
    
    .option__scope{
      font-size: 14px;
      font-weight: bold;
    }
    
    .option__icon{
      font-size: 12px;
      padding: 0 10px;
    }
    
    .option__description{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
    }
  }
}


