
        @import '@/core/assets/scss/abstracts/__variables.scss';
        @import '@/core/assets/scss/abstracts/_animations.scss';
        @import '@/core/assets/scss/abstracts/_fonts.scss';
        @import '@/core/assets/scss/abstracts/_functions.scss';
        @import '@/core/assets/scss/abstracts/_mixins.scss';
        @import '@/core/assets/scss/abstracts/_placeholders.scss';
        
.role__readonly-content{
    ul {
        display: flex;
        flex-flow: wrap;
        list-style-type: none;

        li {
            display: flex;
            position: relative;
            padding: 1px 0;
            
            .role__readonly{
                background-color: palette('blue', 'semiDark');
                border-radius: 5px;
                padding: 4px 10px;
                margin-right: 10px;
                white-space: nowrap;
                overflow: hidden;
                max-width: 100%;
                text-overflow: ellipsis;

                label {
                    line-height: 1;
                    font-size: 16px;
                    color: #fff;
                }
            }
        }
    }
}