/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
.side-panel-body-content {
    transition: margin-left .5s;
    transition: margin-right .5s;
    width:100%;
}

.side-panel {
    &__backdrop {
        position: absolute;
        z-index: 20;
        background: rgba(0,0,0, .5);
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;

        &--remove {
            @extend .side-panel__backdrop;
            display: none;
        }
    }

    &__modal {
        display: flex;
        flex-flow: column;
        position: fixed;
        z-index: 21;
        top: 0;
        height: 100%;
        min-width: 320px;
        background-color: #FFF;
        transition: 0.5s;

        &--left {
            @extend .side-panel__modal;

            left: 0;
            border-right: solid 1px #ccc;
            box-shadow: 5px 0 #0000001f;
        }
        
        &--right {
            @extend .side-panel__modal;

            right: 0;
            border-left: solid 1px #ccc;
            box-shadow: -5px 0 #0000001f;
        }
        
        @for $i from 1 through 100 {
            &--#{$i} {
                @include reverse-breakpoint($small) {
                    width: 100%;
                }

                width: $i * 1%;
            }
        }
    }

    &--sticky-footer{
        .side-panel__content--mobile{
            overflow: unset;
            .side-panel-inside{ 
                .template-body{
                    height: 100%;
                    flex-grow: unset;
                    overflow: auto;
                }
             }
        }
    }

    &__content {
        display: flex;
        flex-flow: column;
        width: 100%;
        height: calc(100% - 50px);
        background-color: #fff;

        &--multiple-tabs{
            height: calc(100% - 100px);
        }

        &--mobile{
            height: calc(100% - 25px);
            overflow: auto;
            .side-panel-inside{            
                .template-body{
                    overflow: unset;
                    height: auto;
                    flex-grow: 1;
                }
                .template-footer {
                    min-height: 50px;

                    .button {
                        min-height: 34px;
                        min-width: 40%;
                        max-width: 45%;
                        font-size: 14px;
            
                        i {
                            font-size: 12px;
                        }
                    }
                }

                .side-panel__close-tab {    
                    button {
                        width: 15px;
                        height: 15px;
                    }
                }
            }
        }
    }

    /* The side navigation menu */
    &__tabs {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        height: 50px;

        @include reverse-breakpoint($medium) {
            height: 25px;
        }

        &--multiple{
            min-height: 100px;

            @include reverse-breakpoint($medium) {
                min-height: 75px;
            }
        }

        .side-panel-tabs__content {
            display: flex;
            flex-grow: 1;
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;

            .side-panel-tabs__button-box {
                display: inline-block;
                width: 100px;

                .side-panel-tab__button {                    
                    display: inline-block;
                    height: 100%;
                    width: 100%;
                    list-style: none;    
                    font-family: font('source-semi-bold');
                    cursor: pointer;

                    &--active {
                        @extend .side-panel-tab__button;
                        background-color: palette('grey', 'light');
                        border-top: 3px solid palette('blue');
                        color: palette('blue');
                    }
                }
    
                :disabled:not(.side-panel-tab__button--active) {
                    opacity: 0.5;
                    cursor: unset;
                }
            }
        }  
      
        .side-panel__close-tab {
            display: flex;
            height: 49px;
            padding: 15px 5px 15px 0px;

            @include reverse-breakpoint($medium) {
                height: 25px;
                padding: 5px 5px 0 0;
            }

            button {
                padding: 10px;
                min-height: 10px;
                width: 30px;
                border: none;

                @include reverse-breakpoint($medium) {
                    width: 20px;
                    height: 20px;
                    padding: 0
                }
            }
        }
    }

    /* The navigation menu links */
    a {
        padding: 8px 8px 8px 32px;
        color: #818181;
        display: block;
        transition: 0.3s;

        /* When you mouse over the navigation links, change their color */
        :hover {
            color: #f1f1f1;
        }
    }
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@include reverse-breakpoint($small) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}

.side-panel-inside {
    display: flex;
    flex-flow: column;
    height: 100%;

    .side-panel-form {
        display: flex;
        flex-flow: column;
        height: 100%;
    }

    .template-header {
        height: 10%;
        min-height: 100px;
        display: flex;
        align-items: center;
        position: relative;
    
        @include reverse-breakpoint($small) {
            min-height: 50px;
        }

        &__center {
            display: flex;
            justify-content: center;
            align-items: center;  
            height: 100%;
            width: 100%;
            position: relative;

            .template-header__icon {
                color: palette('grey', 'base');
                position: absolute;
                left: 20px;
                i {
                    font-size: 40px;

                    @include reverse-breakpoint($xlarge) {
                        font-size: 30px;
                    }
                }
            }

            .template-header__title {
                font-size: 30px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                height: 100%;
                width: calc(100% - 175px);
                overflow: hidden;
                font-family: font('source-semi-bold');
                color: palette('grey', 'semiDark');

                @include reverse-breakpoint($xlarge) {
                    font-size: 20px;
                }

                &--medium{
                    @extend .template-header__title;
                    font-size: 26px;
                }

                .ellipsis {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-align: center;
                    width: 100%;
                }
            }
        }
    }

    .template-body {
        display: flex;
        flex-flow: column;
        height: 100%;
        align-items: center;
        overflow: auto;
        background-color: palette('grey', 'light');
        position: relative;
        color: #40454c;
        padding: 20px $template-body-sides-padding;
        
        &.search-list-correction{
            padding: 20px 0;

            .template-body__row, .template-body__row--wrap {
                padding-left: 15px;
                padding-right: 15px;            
            }

            .search-list-overflow {
                overflow: auto;
                height: 100%;
                @include reverse-breakpoint($small) {
                    overflow: unset;
                }
            }    
        }

        &.flex-column {
            flex-direction: column;
        }

        &__block {
            width: 100%;
            height: 100%;
            display: flex;
            flex-flow: column;
            align-items: center;
            padding: 15px 0;
            
            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
            }
        }

        &__wrap {
            display: flex;
            flex-flow: wrap;
            width: 100%;
        }

        &__row {
            display: flex;
            flex-flow: column;
            width: 100%;
            padding: 10px 0;
            
            &--wrap {
                @extend .template-body__row;
                flex-flow: row;

                .template-body__row{
                    padding: 0 5px;
                    &:first-child {
                        padding-left: 0;
                    }
        
                    &:last-child {
                        padding-right: 0;
                    }
                }
            }

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
            }

            @for $i from 1 through 100 {
                .template-body__element--#{$i} {
                    display: flex;
                    flex-flow: column;
                    width: $i * 1%;
                    padding: 0 5px;

                    &:first-child {
                        padding-left: 0;
                    }

                    &:last-child {
                        padding-right: 0;
                    }

                    button{
                        font-size: 16px;
                    }
                }
            }

            .template-body__span {
                font-size: 12px;
                font-weight: 700;
                font-family: font('sans-serif');
                color: palette('grey', 'semiDark');
                padding-bottom: 10px;
            }

            .template-body__icon {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &__radio {
            display: flex;
            flex-flow: row;
            width: 100%;
        }

        &__checkbox{
            display: flex;
            flex-flow: column;
            width: 15%;
        }
            
        &__separator{
            width: 100%;
            padding: 15px 0;
            div{
                width: 100%;
                border-style: solid;
                border-top-width: 0;
                border-bottom-width: 1px;
                border-color: #ccc;
            }
        }
    }

    .template-loading{
        width: 100%;
        height: 100%;
        display: flex;            
        position: absolute;
        flex-flow: column;
        align-items: center;
        top: 0;
        left: 0;
        z-index: 1;
        padding: 15px 0;
        opacity: 0.5;
        background-color: palette('grey', 'light');
    }

    .template-footer {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 10%;
        min-height: 110px;

        .button {
            line-height: 1;
            min-height: 64px;
            min-width: 40%;
            max-width: 45%;

            i {
                padding-right: 10px;
                font-size: 16px;

                @include reverse-breakpoint($medium) {
                    font-size: 12px;
                }
            }
        }

        &.shadowy {
            box-shadow: 0px -60px 50px palette('grey', 'light');
        }
    }

    // TODO: Replace by template-header (address.html, user.html, user-access-list.html)
    .side-panel-inside-title {
        height: 10%;
        font-size: 25px;
        color: #40454c;
        font-family: source_sans_probold, arial, sans-serif;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid;
        border-color: whitesmoke;
    }
  
    // TODO: Think to change this if have time.
    .side-panel-inside-content {
        height: 100%;
        overflow-y: auto;

        .field-container {
            display: grid;
            padding-left: 2%;
            padding-right: 2%;
            height: 100%;

            .input-wrap {
                label {
                    padding: 12px 0;
                }
            }

            .side-panel-inside-row {
                padding: 0 10px;
            }
        }
    }
}
  
// TODO: Replace by template-footer (address.html, user.html, user-access-list.html)
.side-panel-inside-controls {
  height: 10%;
  font-size: 14px;
  display: flex;
  flex-flow: wrap;
  justify-content: space-evenly;
  align-items: center;
  width:100%;

  .button {
    min-height: 45px;
    min-width: 40%;
  }
}

// TODO: Remove the class in user.scss
.flex-align {
    display: flex;
    align-items: center;
}

/* Close Button */
[class*='close-'] {
  color: #777;
  font: 20px/100% arial, sans-serif;
  right: 5px;
  text-decoration: none;
  text-shadow: 0 1px 0 #fff;
  top: 5px;
}

.close-classic:after {
  content: 'X'; /* ANSI X letter */
}

.close-thin:after {
  content: '×'; /* UTF-8 symbol */
}

.close-thik:after {
  content: '✖'; /* UTF-8 symbol */
}

@for $i from 1 through 100 {
  .side-panel-#{$i}PercentWidth {
    @include reverse-breakpoint($small) {
      width: 100%;
    }
    width: $i * 1%;
  }
}

@for $i from 1 through 10 {
  .column-grid-start-#{$i} {
    grid-column-start: $i;
  }

  .column-grid-end-#{$i} {
    grid-column-end: $i;
  }

  .row-grid-start-#{$i} {    
    grid-row-start: $i;
  }
  
  .row-grid-end-#{$i} {
    grid-row-end: $i;
  }
}