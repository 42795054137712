.update-block{
    position: absolute;
    z-index: 22;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: calc(50% - 200px);
    
    @include reverse-breakpoint($medium){
        bottom: 0px;
    }
}