
.product-results__footer::before{
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    left: -$template-body-sides-padding;
    top: 0;
    width: calc(100% + (#{$template-body-sides-padding} * 2));
    border-top: 2px solid darkgrey;
}

.product-results__footer {
    width: 100%;
    min-height: $product-body-results-height;
    padding-top: 20px;
    font-size: 18px;
    position: relative;

    @include reverse-breakpoint($medium) {
        min-height: unset;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    input {
        color: palette('grey', 'semiDark');
        border: 1px palette('grey', 'semiLight') solid;
        border-radius: 6px;
        height: 35px;
        padding: 5px;
        text-align: right;

        @include reverse-breakpoint($small) {
            font-size: 16px;
        }

        :disabled {
            background-color: #c5c5c5;
        }
    }

    .product-results__row {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 8px 0;

        .product-results__row-text {
            width: 30%;
            font-size: 24px;
            margin-left: 15px;
        }
    }
}