.widget-config{
    display: flex;
    flex-flow: column;
    height: 100%;

    .widget-config-tabs{
        width: 100%;
        height: fit-content;
        display: flex;
        padding: 10px;
    }

    .widget-config-body{

        &__span{
            font-size: 18px;
        }

        &__info-block {
            display: flex;
            width: 100%;
            flex-flow: column;
            padding-top: 5px;

            .widget-config-body-field {
                padding: 5px 0;
            }
        }

        &__period {
            .period-value {
                font-size: 16px;
            }
        }

        &__filter{
            .toggle{
                align-items: center;
            }
            .badge{
                font-size: 10px;
                padding: 1px;
                margin-left: 5px;
            }
        }
    }
}

#side-panel-id {
    min-width: 340px;
}