.widget {
    .widget__body {
        .widget__content {
            @include reverse-breakpoint($small) {
              padding: 10px 0;
            }

            .widget__chart {
                width: 100%;
                height: calc(100% - 20px);
            }
        }
    }
}

.ag-chart-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}

.ag-chart-tooltip {
    background-color: #005494;
    border-radius: 0;
}

.ag-chart-tooltip-title {
    padding-bottom: 3px;
    background-color: #005494 !important;
    color: #FFF;
}

.ag-chart-tooltip-content {
    padding-top: 0px;
    color: #FFF;
}


.ag-chart-tooltip::after {
    border-top-color: #005494;
}
