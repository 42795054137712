.menu-wrap{
  display: flex;
  align-items: center;
  height: 100%;    
  width: 100%;

  .panel__renderer {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    height: 100%;
    width: calc(100% - 15px);

    .span-value{      
      color: palette('blue', 'base');
      cursor: pointer;
    }
  }

  .span-value__wrap{
    width: 90%;
    display: flex;

    .span-value {        
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }


  .menu-button-wrap {
    width: 20px;
    padding-left: 5px;
    position: relative;

    .menuVisible{
      display: block;
    }

    .menuNotVisible{
      display: none;
    }

    #menuRenderer__menu{
      font-size: 16px;
      cursor: pointer;
    }

    .open-right{
      right: -175px;
    }

    .open-left{
      right: 20px;
    }
  
    .menu-content{
      position: absolute;
      background-color: #f5f7f7;
      min-width: 160px;
      overflow: auto;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 1;
      border-radius: 5px;
      border: 1px solid;
      border-color: palette('grey', 'light');
      top: 0px;

      &.open-top{
        top: -95px;
      }

      .menu-element:not(:last-child){
        border-bottom: 1px solid palette('grey', 'light');
      }

  .menu-element{
    display: flex;
    flex-flow: wrap;
    width: 160px;
    cursor: pointer;

    .element-button{
      display: flex;
      flex-flow: wrap;
      align-items: center;
      width: 100%;
      
      .element-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        width: 20px;
        margin: 0 10px;
      }
    }
  }
      :hover{
        background-color: palette('grey', 'light');
      }      
    }    
  }
}

.ag-row-selected, .ag-row-hover{
  .menu-button-wrap{
    #menuRenderer__menu{
      display: block;
    }
  }
}