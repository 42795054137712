.purchase-info{
  .receiving-info__block{  
    &--1{
      flex-grow: 1;        
      min-width: 250px;
      @include reverse-breakpoint($medium) {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
      }
    }
    &--2{
      flex-grow: 0.5;
      width: 250px;
      @include reverse-breakpoint($medium) {
        padding-left: 0;
        width: 50%;
      }
      @include reverse-breakpoint($small) {
        padding-left: 0;
        min-width: unset;
      }
    }
    &--3{
      @include reverse-breakpoint($medium) {
        padding-right: 5px;
        width: 50%;
      }
    }
    &--4{
      flex-grow: 0.5;
      padding-left: 0;
      width: 50%;
    }
    &--5{
      flex-grow: 0.5;
      padding-right: 0;
      width: 50%;
    }
  }
}