.side-nav {
  background-color: palette('grey', 'dark');
  color: #fff;
  height: 100%;
  left: 0;
  overflow: hidden;
  padding-top: $header-desktop-height;
  position: fixed;
  top: 0;
  transition: width 0.5s ease 0.2s;
  width: 0;
  z-index: 7;

  @include breakpoint($small) {
    width: $side-nav-desktop-closed-width;
  }
}

.side-nav--open {
  transition-delay: 0s;
  width: 100%;

  @include breakpoint($small) {
    width: $side-nav-desktop-open-width;
  }
}

.side-nav__header {
  align-items: center;
  align-content: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: $header-desktop-height;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;

  .option {
    padding: 20px;
    display: flex;
    align-items: center;
  }

  &.header-open {
    justify-content: flex-end;

    &.mobile {
      justify-content: space-between;
    }
  }

  &-logo {
    color: #50C8E8;
    margin: 10px;
  }

  .open {
    background-color: palette('grey', 'semiDark');
  }

  
}

.side-nav__button-icon {
  fill: palette('white');
  transform: scale(-1, 1);
  transition: fill 0.5s ease;
  width: 20px;
  margin: 20px;

  &:hover {
    fill: palette('blue');
  }

  @include breakpoint($small) {
    transform: scale(1, 1);

    .side-nav--open & {
      transform: scale(-1, 1);
    }
  }
}

.side-nav__content {
  height: 100%;
  opacity: 1;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  user-select: none;

  width: 100%;

  @include breakpoint($small) {
    height: calc(100% - #{$footer-desktop-height});
    width: $side-nav-desktop-open-width;
  }

  .side-nav--open & {
    opacity: 1;
    transition-delay: 0.5s;
    visibility: visible;
  }
}
.sideLogoOpacity{
  opacity: 1;
  transition-delay: 0.5s;
  visibility: visible;
}
.side-nav__links {
  @include font-smoothing();
  border-bottom: 1px solid palette('grey', 'semiDark');
  font-family: font('source');
  font-size: 1.125em;
  list-style: none;
  font-weight: bold;

  &.capitalized{
    text-transform: capitalize;
  }

  .side-nav__second-links {
    font-family: font('source-regular');
    font-size: 16px;
  }
}
.selected-parent{
  border-left:2px solid palette('blue');
  color: #50C8E8;
  font-weight: bolder;
}
.unselected-parent{
  color: palette('white');
  font-weight: bold;
}
.unselected-nav{
  color: palette('white');
  font-weight: normal;
}

.side-nav__links--current-child {
  background-color: palette('grey', 'semiDark');
  padding-bottom: 10px;
}
.side-nav__second-links {
  font-family: font('source');
  font-size: 0.85em;
  list-style: none;
}
.side-nav__third-links {
  font-family: font('source');
  font-size: 0.85em;
  list-style: none;
  padding-left: 30px;
}

.side-nav__link {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 20px $gutter-desktop;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: palette('grey');
    color: #50C8E8;
    font-weight: 800;
  }
  .side-nav__third-links, .side-nav__second-links & {
    padding: 10px $gutter-desktop;
    background-color: palette('grey', 'semiDark');

    &:hover {
      background-color: palette('grey');
    }
  }
}

.side-nav__third-links, .side-nav__second-links .side-nav__link--current {
  background-color: palette('grey', 'base');
  color: #50C8E8;
}

.side_nav_logo {
  width: 40px;
  height: 80%;
  margin-right: 5%;
  font-size: 30px;
  color: #50C8E8;
}

@media only screen and (max-width: 640px) {
  .side-nav__content {
    opacity: 0;
    transition-delay: 0.5s;
    visibility: visible;
  }
  .side-nav{
    opacity: 1;
    transition-delay: 0.1s;
  }
}

.user-menu-options {
  background-color: palette('grey', 'semiDark');

  .option {
    padding: 10px;

    a {
      all: unset;
    }
  }
}
