.max500 {
    color: red;
    margin-left: 1em;
}

.filters {
    display: flex;

    .date-selector {
        width: 30%;
        min-width: 200px;
        max-width: 400px;
        margin-right: 1em;

        .multiselect__option i {
            float: right;
        }
    }
}