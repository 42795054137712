.slim-multiSelect{
    min-width: 42px;
    height: 100%;

    &.multiselect{        
        flex-shrink: 0;
        height: 100%;

        .multiselect__content-wrapper{
            width: auto;         
            min-width: 100px;   
            border: 1px solid rgba(136, 136, 136, 0.315);
            border-radius: 7px;
            overflow: unset;
            .multiselect__element{
                height: 30px;

                .multiselect__option{
                    min-height: 30px;                    
                    font-size: 12px;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        } 
        .multiselect__tags{
            background-color: unset;
            padding: 0 5px;
            width: 100%;
        }

        .multiselect__single{
            font-size: 10px;
            font-weight: 700;            
            justify-content: center;
        }
        .multiselect__select{
            width: 0px;

            &:before{
                content: none;
            }
        }
    }
    &.multiselect--disabled{
        cursor: unset;
        background-color: unset;
    }
}

.multiselect--grey{
    .multiselect{
        .multiselect__single, .multiselect__option, .multiselect__option--selected {
            font-family: font('source-semi-bold');
            font-size: 14px;
            color: palette('grey', 'semiLight');
        }
    }
}