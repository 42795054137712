.search-list {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;

    .search-list__search-bar {
        display: flex;
        flex-flow: column;

        .search-list__search-bar__span {
            font-size: 12px;
            font-weight: 700;
            font-family: font('sans-serif');
            color: #40454c;
            padding-bottom: 10px;
            
            input{
                padding-left: 10px;
            }
        
            i{
                font-size:25px;
            }
        }
    }

    .search-list__actions-scan{
        display: flex;
        justify-content: center;
        align-items: center;
        span{
            padding-right: 10px
        }
    }

    .search-list__list_empty, .search-list__list_loading {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;

        button{
            margin-top: 10px;
        }
    }
    
    .search-list__box {
        display: flex;
        flex-flow: column;
        width: calc(100% + 10px);
        height: 100%;
        overflow: auto;
        padding-right: 5px;
        scrollbar-gutter: stable;
        position: relative;

        .search-list__scan{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: 1;

            .scan-body__scan-icon{
                i{
                    font-size: 100px;
                }
                span{
                    font-size: 25px;
                }
            }
        }
        
        .search-list__content {
            display: flex;
            flex-flow: wrap;
            width: 100%;        
            z-index: 2;

            .search-list__element-list {
                display: flex;
                align-items: center;
                width: 100%;
                height: fit-content;
                margin-bottom: 3px;
                cursor: pointer;
                background-color: #fff;
    
                &:first-child{
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                }
                &:last-child{
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                }

                .search-list__checkbox {
                    height: 100%;
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                    width: 45px;
                    padding: 0 10px;
                }
        
                .search-list__element {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
    
                    &.checkbox-display {
                        width: calc(100% - 50px);
                    } 
                }

                &.remove-pointer {
                    cursor: initial;
                }
        
                &:hover {
                    background: palette('grey', 'gainsboro');
                }

                &.disabled {
                    opacity: 0.4;
                    background: #fff;
                }
            }
        }
    }
    &__actions-all{
        padding: 15px 0;
        display: flex;
        justify-content: space-between;
        button{
            width: 184px;
            font-size: 16px;
            min-height: 32px;
            height: 32px;

            @include reverse-breakpoint($medium){
                line-height: 1;
                font-size: 14px;
            }
        }
        button.button--dark{
            padding: 0 10px;
        }
    }
}