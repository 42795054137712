$text-theme: (
    'base': 'sans-serif',
    'light': 'source-light',
    'regular': 'source-regular',
    'semi-bold': 'source-semi-bold',
    'bold': 'source-bold'
);

$universal-modifier: (
    'small': (
        'font-size': 12px
    ),
    'bigger': (
        'font-size': 16px
    ),
    'bolder': (
        'font-weight': 700
    )
);


@mixin add-universal-modifier($class) {
    @each $modifier-name in map-keys($universal-modifier) {
        $modifier: map-get($universal-modifier, $modifier-name);
        
        &--#{$modifier-name} {
            @each $c in $class {
                @extend .#{$c};
            }
            
            @each $style in map-keys($modifier) {
                #{$style}: map-get($modifier, $style);
            }
        }
    }
}


@each $theme in map-keys($text-theme) {
    $class-name: text-#{$theme};
    .#{$class-name} {
        color: palette('grey', 'base');
        font-family: font(map-get($text-theme, $theme));
        font-size: 14px;
        
        @include add-universal-modifier(#{$class-name});
        
        &__link {
            @extend .#{$class-name};
            cursor: pointer;
            font-weight: 600;
            color: palette('blue', 'base');
            
            @include add-universal-modifier(#{$class-name} #{$class-name}__link);
        }
    
        &__description, &__description--compact {
            @extend .#{$class-name};
            color: palette('grey', 'semiLight');

            &--compact {
                letter-spacing: 0.05em;
            }
            
            @include add-universal-modifier(#{$class-name} #{$class-name}__description #{$class-name}__description--compact);
        }
    
        &__title {
            @extend .#{$class-name};
            color: palette('grey', 'semiLight');
            font-weight: 600;
            
            @include add-universal-modifier(#{$class-name} #{$class-name}__title);
        }
    
        &__title-dark {
            @extend .#{$class-name};
            color: palette('grey', 'semiDark');
            font-weight: 700;
            
            @include add-universal-modifier(#{$class-name} #{$class-name}__title-dark);
        }
    }
}