
section {
    display: flex;
    align-self: flex-start;
}

.icon-button {
    width: 265px;
    margin-left: 15px;
    padding: 0 10px;

    span {
        padding-right: 10px;
    }

    &--mobile {
        @extend .icon-button;
        
        position: absolute;
        right: 25px;
        width: 30px;
        bottom: 30px;
        border-radius: 50%;
        box-shadow: 0 0 10px #00000085;
        z-index: 5;
    }
}
