@include breakpoint($xxsmall) {
  @for $i from 1 through 12 {
    .col-xxs-#{$i} {
      width: calc(#{$i} * 8.33333333%);
    }
  }
}
@include breakpoint($xsmall) {
  @for $i from 1 through 12 {
    .col-xs-#{$i} {
      width: calc(#{$i} * 8.33333333%);
    }
  }
}
@include breakpoint($small) {
  @for $i from 1 through 12 {
    .col-sm-#{$i} {
      width: calc(#{$i} * 8.33333333%);
    }
  }
}
@include breakpoint($medium) {
  @for $i from 1 through 12 {
    .col-md-#{$i} {
      width: calc(#{$i} * 8.33333333%);
    }
  }
}
@include breakpoint($large) {
  @for $i from 1 through 12 {
    .col-lg-#{$i} {
      width: calc(#{$i} * 8.33333333%);
    }
  }
}
@include breakpoint($xlarge) {
  @for $i from 1 through 12 {
    .col-xlg-#{$i} {
      width: calc(#{$i} * 8.33333333%);
    }
  }
}