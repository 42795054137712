.bullet{
  list-style-type: none;
  :first-child::before{
    padding: 0 10px;
    content:url('@/img/bullet.svg');
  }
}

.rmv-bullet::before{
    padding: 0 10px;
    content:url('@/img/bullet.svg');
}


.filled-bullet{
  list-style-type: none;
  :first-child::before{
    padding: 0 10px;
    content:url('@/img/filled-bullet.svg');
  }
}
.input-multi{
  list-style-type: none;
  .multiselect__option{
    ::before{
      padding: 0 10px;
      content:url('@/img/bullet.svg');
    }
  }
  .multiselect__option--selected{
    ::before{
      padding: 0 10px;
      content:url('@/img/filled-bullet.svg');
    }
  }
}
