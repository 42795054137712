.statistic-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 30px 0 0;
}

.statistic-block__label {
  @include font-smoothing();
  border-bottom: 1px solid palette('grey', 'light');
  cursor: pointer;
  display: flex;
  font-family: font('source-bold');
  height: 60px;
  line-height: 60px;
  padding: 0 $gutter-desktop;
  text-transform: uppercase;
  transition: color 0.3s ease;
  user-select: none;
  justify-content: space-between;
  &:hover {
    color: palette('blue', 'dark');

    .svg {
      fill: palette('blue', 'dark');
    }
  }
}
.statistic-block__label-arrow {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 10px;

  .svg {
    width: 100%;
  }
  &.left{
    transform: rotate(-180deg);
  }
}
.statistic-block__blocks {
  background-color: #fff;
  border-bottom: 2px solid palette('grey', 'semiLight');
  color: palette('grey', 'semiDark');
  display: block;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  font-family: font('source-bold');
  height: 100%;
}

.h-nav {
  display: inline-block;
  color: #40454c;
  font-family: font('source-bold');
  padding: 11px 5px 0 20px;
}

.h-nav--current {
  background-color: rgb(80, 200, 232);
}

.h-nav > li {
  float: left;
}

.statistic-block__content {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  height: 450px;
  width: 100%;
}

.statistic-block__content-left {
  width: 30%;
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 2px solid #efeeed;
  cursor: pointer;

  .statistic-block__item {
    width: 100%;
    flex: 1;
    padding: 20px;
    position: relative;
    min-height: 125px;
    justify-content: center;
    align-items: center;
    display: flex;
    @include reverse-breakpoint($small) {
      padding: 5px;
      min-height: 175px;
    }
  }
}

.statistic-block__content-right {
  height: 100%;
  width: 70%;
  display: flex;
  flex-wrap: wrap;

  .statistic-block__item {
    width: 50%;
    flex: 1 1 auto;
    float: left;
    position: relative;
  }
}

.statistic-block__item-Clickable {
  transition: background-color 0.5s ease, opacity 0.3s, border-color 0.5s ease, color 0.5s ease, transform 0.3s ease;
  opacity: 0.3;
  display: flex;
  flex-flow: wrap;
  width: 100%;
  height: 100%;

  &:hover {
    opacity: 0.7;
  }
  @include reverse-breakpoint($small) {
    flex-flow: column;
  }
}

.statistic-block__item-info {
  width: 70%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  @include reverse-breakpoint($small) {
    font-size: 14px;
    text-align: center;
    width:100%;
    height: 50%;
  }
  @include reverse-breakpoint($xsmall) {
    font-size: 12px;
  }
}

.statistic-block__item-icon {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .statistic-block__item-gauge{
    min-width: 80px; 
  }
  span {
    position: absolute;
  }
  @include reverse-breakpoint($small) {
    font-size: 12px;
    width:100%;
    height: 50%;
    .statistic-block__item-gauge{
      min-width: 40px;
      width: 60px;
      height: 60px;
    }
  }

}

.statistics-block__stats {
  height: 100%;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  @include reverse-breakpoint($small) {
    flex-flow: column;
  }
}

.statistics-block__stats-icon {
  height: 100%;
  width: 50%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-end;
  padding: 20px;
  @include reverse-breakpoint($small) {    
    width: 100%;
    font-size: 12px;
    padding: 5px;
    align-items: center;
    .statistics-block__stats-gauge{
      width:40px;
    }
  }
}

.statistics-block__stats-info {
  text-transform: uppercase;
  font-family: font('source-bold');  
  font-size: 16px;
  height: 100%;
  width: 50%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  @include reverse-breakpoint($small) {
    width: 100%;
    align-items: center;
    text-align: center;
    font-size: 12px;
    padding: 5px;
  }
}
.statistics-block__stats-info__wrap{
  @include reverse-breakpoint($small) {
    width: 100%;
    word-break: break-word;
  }

}
.opacityItem {
  opacity: 1;

  &:hover {
    opacity: 1;
  }
}

.loading {
  opacity: 0.3;
  min-width: 70%;
  min-height: 100%;
}

.statistic-block__underValue{
  color: palette('grey', 'base')
}