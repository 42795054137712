.time-picker {
    display: flex;
    flex-flow: row;
    align-content: center;
    justify-content: flex-end;
    padding: 0 5px;
    width: 100%;
    color: #40454c;

    .time-picker__input {
        display: flex;
        width: 15px;

        input[type=number],
        input[type=text] {
            text-align: right;
        }

        input:disabled {
            background-color: #FFF;
        }
    }

    .time-picker__label {
        display: flex;
        padding: 0 4px;
    }

    .time-picker__control {
        display: flex;
        flex-flow: column;
        padding: 0 5px;
        font-size: 12px;

        &.time-picker__control-disabled {
            opacity: 1;
        }

        .time-picker__control-up {
            display: flex;
            cursor: pointer;
        }

        .time-picker__control-down {
            display: flex;
            cursor: pointer;
        }
    }
}