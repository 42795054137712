.labels {
    .button-control a {
        text-decoration: none; 
    }
    
    .router-link-active {
        font-weight: bold;
    }
    
    .router-link-active i {
        color: #50C8E8;
    }
}
