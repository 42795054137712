.container{
    height: 100%;
    padding-left: 23px;
    display: flex;
    align-items: center;
    @include reverse-breakpoint($small) {
      padding-left: 10px;
    }
    span{
      z-index: 1;
    }
    .text-semi-bold{
      font-size: 16px;
      font-family: font('source-bold');      
    }
}