.options-tool-panel {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    padding: 20px 10px;

    .page-size-picker {
        label {
            font-weight: bold;
        }
    
        .dropdown {
            margin-bottom: 1em;
            padding: 5px 10px;
            width: 100%;
        }
    }

    .button, .button--slim {
        .options-tool-panel__label {
            padding-left: 10px;
        }
    }
}