
        @import '@/core/assets/scss/abstracts/__variables.scss';
        @import '@/core/assets/scss/abstracts/_animations.scss';
        @import '@/core/assets/scss/abstracts/_fonts.scss';
        @import '@/core/assets/scss/abstracts/_functions.scss';
        @import '@/core/assets/scss/abstracts/_mixins.scss';
        @import '@/core/assets/scss/abstracts/_placeholders.scss';
        
.icon-picker-container {
    height: 100%;
    border-width: 2px;
    border-style: solid;
    border-color: #DFDFDF;
    border-radius: 8px;
    padding: 5px;
    display: flex;
    flex-direction: column;

    .icon-container {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 48px;
        color: #095998;
    }

    .picker-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .picker-container-input {
            flex-grow: 1;
            border-radius: 10px;
            border-width: 2px;
            border-style: solid;
            border-color: #DFDFDF;
            text-align: center;
            font-size: 12px;
            width: 60px;
            padding: 5px;
        }

        .picker-container-link {
            margin-left: 5px;
            padding: 0px;
        }

        a, a:visited, a:hover, a:active {
            color:#000000;
            text-decoration: none;
        }
    }
}