.header {
  align-items: center;
  background-color: palette('white', 'base');
  color: palette('white', 'base');
  display: flex;
  height: $header-desktop-height;
  justify-content: space-between;
  padding: 0 25px 0 105px;

  @include reverse-breakpoint($small){
    position: fixed;
    width: 100%;
    z-index: 6;
    background-color: #fff;
    padding: 0 25px;
  }

  .header__mobile-menu-icon {
    align-items: center;
    cursor: pointer;
    display: none;
    height: 100%;
    justify-content: center;
    margin-left: -($gutter-desktop);
    padding: 0 $gutter-desktop;
    width: 75px;
    i{
      color: palette('grey', 'dark');
      font-size: 26px;
    }

    @include reverse-breakpoint($small) {
      display: flex;
    }
  }

  .header__menu {
    li {
      display: inline-block;
      list-style: none;
      position: relative;
      vertical-align: top;

      i{
        font-size: 22px;
      }
    }
    
    .header__menu-link {
      font-size: 1em;
      height: $header-desktop-height;
      padding: 0 20px;
      display: flex;
      flex-flow: wrap;
      align-items: center;
        
      .link__text{
        padding-right: 7px;
      }
    }
  }
}