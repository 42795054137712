@keyframes loadin {
    0%{
        opacity: 0;
    }
    50%{
        opacity: 0.5;
    }
    100%{
        opacity: 1;
    }
}
$border-color:#c8c8c8;
$background-color:#ffffff;

.sir-tooltip{
     margin: 0 2px;
    display: flex;
    .description{
        display: flex;
        align-items: center;
        position: absolute;
        border: 1px $border-color solid;
        padding: 5px;
        border-radius: 5px;
        z-index: 2;
        background-color: $background-color;
        font-size: 14px;
        animation: loadin 0.5s ease-out ;
    }
}
.tooltip-bottom{
    flex-direction: column;
    .arrow-down {
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 9px solid $border-color;
        position: absolute;
        margin-top: 15px;
        margin-left: 1px;
      }
    .description{
        left: 115px;
        margin-top: 24px;
        min-height: 36px;
    }
}