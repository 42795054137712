
        @import '@/core/assets/scss/abstracts/__variables.scss';
        @import '@/core/assets/scss/abstracts/_animations.scss';
        @import '@/core/assets/scss/abstracts/_fonts.scss';
        @import '@/core/assets/scss/abstracts/_functions.scss';
        @import '@/core/assets/scss/abstracts/_mixins.scss';
        @import '@/core/assets/scss/abstracts/_placeholders.scss';
        
$panels-margin-bottom: 15px;
$step-row-margin-bottom: 10px;
$total-bo-heading-height: $heading-padding-top + $heading-padding-bottom + $heading-title-height;
$total-step-height: $step-height + $step-row-margin-bottom;
$content-remaining-height: calc(100vh - #{$header-desktop-height} - #{$total-bo-heading-height} - #{$total-step-height} - #{$button-height} - #{$gutter-desktop});

.step-row{
    margin-bottom: $step-row-margin-bottom;
}

.create-update{
    display: flex;
    flex-flow: column;
    height: $content-remaining-height;
    flex-grow: 1;

    @include reverse-breakpoint($medium) {
        height: 100%;
    }

    .create-update__panels{
        display: flex;
        flex-flow: row;
        height: calc(100% - #{$span-height} - #{$panels-margin-bottom});        
        margin-bottom: $panels-margin-bottom;

        .panel {
            display: none;
        
            .panel__content-left{
                display: flex;
                flex-flow: column;
                width: 50%;
                padding-right: 10px;

                @include reverse-breakpoint($medium) {
                    width: 100%;
                    padding-right: 0;
                }
            }
            .panel__content-right{
                display: flex;
                flex-flow: column;
                width: 50%;
                padding-left: 10px;

                @include reverse-breakpoint($medium) {
                    width: 100%;
                    padding-left: 0;
                }
            }
            .visualize__options {
                display: flex;
                flex-flow: column;
                width: 100%;
                height: 100%;

                #getReportData {
                    width: fit-content;
                    margin-bottom: 10px;
                    display: flex;
                    align-self: flex-end;
                }

                #GridCreateUpdate {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        
        .panel.active {
            display: flex;
            width: 100%;
            height: 100%;
            @include reverse-breakpoint($medium) {
                flex-flow: column;
            }
        }
    }
}
