@for $i from 0 through 10 {
   .row-grid-start-#{$i} {
      grid-row-start: #{$i} + 1;
   }
}
@for $i from 0 through 10 {
   .row-grid-end-#{$i} {
      grid-row-end: #{$i} + 1;
   }
}

@for $i from 0 through 4 {
   .column-grid-start-#{$i} {
      grid-column-start: #{$i} + 1;
   }
}

@for $i from 0 through 4 {
   .column-grid-end-#{$i} {
      grid-column-end: #{$i} + 1;
   }
}