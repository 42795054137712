button {
  background-color: transparent;
  border: initial;
  cursor: pointer;
  outline: none;
}

.button {
  @include font-smoothing();
  align-items: center;
  justify-content: center;
  height: $button-height;
  min-height: $button-height;
  background-color: palette('white', 'base');
  border: 1px solid palette('white', 'base');
  border-radius: 7px;
  color: palette('grey', 'semiDark');
  cursor: pointer;
  display: flex;
  font-family: font('source-semi-bold');
  font-size: 1.1em;
  padding: 0 20px;
  text-align: center;
  transition: background-color 0.5s ease, border-color 0.5s ease, color 0.5s ease, transform 0.3s ease;
  user-select: none;
  fill: palette('blue', 'dark');

  &--grey {
    background-color: #efeeed;
  }

  &:hover {
    border-color: palette('grey', 'semiLight');
  }

  &--slim {
    @extend .button;
    padding: 0 5px;
    font-size: 0.8em;
    height: 25px;
    min-height: 25px;
  }

  @media (hover: hover) {
    &:active {
      transform: scale(0.97);
    }
  }
  &:disabled, &.disabled{
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.button-control {
  display: flex;
  font-size: 30px;
  
  @include reverse-breakpoint($medium) {
    width: 100%;
    padding: 0;

    @include reverse-breakpoint($small) {
      font-size: 20px;
    }
  }

  &.icon{
    button{
      i{
        height: 50%;
        display: flex;
        align-items: end;
      }
      span{
        height: 50%;
        display: flex;
        align-items: center;
        padding: 5px 10px;
        font-size: 16px;
        font-family: 'source_sans_proregular';
        @include reverse-breakpoint($small) {
          font-size: 14px;
        }
      }
    }
    &--small{
      @extend .icon;
      font-size: 30px;
      @include reverse-breakpoint($small) {
        i{
            font-size: 20px;
        }
      }
    }
    &--medium{
      @extend .icon;
      font-size: 40px;
      @include reverse-breakpoint($small) {
        i {
            font-size: 25px;
        }
      }
    }
    &--big{
      @extend .icon;
      font-size: 50px;
      @include reverse-breakpoint($small) {
        i{
            font-size: 35px;
        }
      }
    }
    &--darkgrey{
        @extend .icon;
        color: palette('grey', 'base')
    }
  }

  button{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 150px;
    background-color: white;
    border-bottom: 1px solid;
    border-right: 2px solid;
    border-color: #ccc;
    -webkit-box-shadow: 3px 3px 3px #ccc;
    box-shadow: 0px 2px 5px #ccc;

    @include reverse-breakpoint($small) {
      height: 100px;
      width: 100px;
    }
  }
}

.button--light {
  background-color: palette('white', 'base');
  border: 1px solid palette('grey', 'semiLight');
  fill: palette('blue', 'dark');
}

.button--dark-grey {
  min-width: 200px;
  min-height: 150px;
  background-color: palette('grey');
  border: 0;
  color: palette('white');
  fill: palette('white');

  &:hover {
    background-color: palette('grey', 'semiLight');
  }
}

.button--blue {
  background-color: palette('blue', 'dark');
  border: 0;
  color: palette('white');
  fill: palette('white');
  font-family: font('source-regular');
  &:hover {
    background-color: palette('blue', 'semiDark') !important;
  }
}

.button--large{
  min-height: 70px;
}

.button--red {
  background-color: #ff3c3c;
  border: 0;
  color: palette('white');
  fill: palette('white');

  &:hover {
    background-color: #ff5050;
  }
}

.button--green {
  background-color: #40b282;
  border: 0;
  color: palette('white');
  fill: palette('white');

  &:hover {
    background-color: #4bb387;
  }
}

.button--dark {
  background-color: #585C62;
  border: 0;
  color: palette('white');
  fill: palette('white');

  &:hover {
    background-color: #3b3f46;
  }
}

.square__button{
  width: 42px;
  height: $button-height;
  font-size: 16px;
  color: rgba(136, 136, 136, 0.315);
  cursor: pointer;
  margin-left: 2px;
  border: 1px solid;
  border-radius: 7px;
  border-color: rgba(136, 136, 136, 0.315);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.1s;
    
  .fa{
    height: 12px;
  }
  &:hover{    
    color: palette('blue', 'base');
    border-color: palette('blue', 'base');
  }
  &:disabled{  
    color: rgba(136, 136, 136, 0.315);
    border-color: rgba(136, 136, 136, 0.315);
    pointer-events: none;
  }
  &:focus{
    color: palette('blue', 'base');
    border-color: palette('blue', 'base');
  }
}