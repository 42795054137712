
        @import '@/core/assets/scss/abstracts/__variables.scss';
        @import '@/core/assets/scss/abstracts/_animations.scss';
        @import '@/core/assets/scss/abstracts/_fonts.scss';
        @import '@/core/assets/scss/abstracts/_functions.scss';
        @import '@/core/assets/scss/abstracts/_mixins.scss';
        @import '@/core/assets/scss/abstracts/_placeholders.scss';
        
.container-tile {
    display: flex;
    flex-direction: column;

    .container-tile-group{
        padding-bottom: 5px;
    }

    .container-tile-row {
        display: flex;
        flex-direction: row;
        padding-top: 5px;
        
        .container-tile-icon {
            display: flex;
            flex-direction: column;
            padding-right: 10px;
            
            .container-tile-icon-picker {
                flex-grow: 1;
                background-color: #FFFFFF;
            }
        }
        
        .container-tile-columns {
            min-width: 0;
            flex-grow: 1;
            
            .container-tile-column-label {
                height: 5px;
                margin-bottom: 0px;
                padding-bottom: 0px;
            }
            
            .container-tile-column-primary {
                padding-bottom: 10px;
            }
        }
    }
}