.user-access{
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    
    .user-access__grid-header{
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        width: 100%;
        padding-bottom: 10px;
        
        :nth-child(n+2) {
            margin-left: 10px;
        }
    }
}
.pos-code-row{
    display: flex;
    flex-direction: row;
    .poscode{
        width: 20%;
    }
    .qrcode{
        display: flex;
        width: 80%;
        justify-content: end;
        align-items: center;
        p{
            padding:12px 10px 0;
            text-align: justify;
            span{
                text-decoration: underline;
                color: palette('blue', 'semiDark');
                cursor: pointer;
            }
        }
        img{
            width: auto;
            height: 70px;
            align-self: center;
        }
        span{
            white-space: nowrap;
            overflow: hidden;
            padding: 0 5px;
            width: 65%;
            text-overflow: ellipsis;
        }
    }
}

#gridAccess, #gridRole, #gridTargetLayer{
    min-height: 300px;

    .ag-cell-value{
        width: 100%;
    }
}