.link{
  cursor: pointer;
}

.form-bot{
  min-width: 220px;
  width: 80%;
  margin: 20px auto 30px auto;
}

.form-action{
  width: 100%;
  padding-top:20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .foot label{
    vertical-align: middle;
    font-family: 'source_sans_proregular';
    font-size: 14px;
    color: #707070;
    padding-right: 10px;
  }

  .action--remember-me{
    display: inline;
    float: left;

    label {
      padding: 10px 20px 0px 0px;
    }
  }

  .action--forgot-password{
    width: 100%;

    a{
      display: inline;
      float: right;
    }
  }
}

.triangle{
  width: 0;
  height: 0;
  border-top: 22px solid transparent;
  border-left: 15px solid  rgba(129,137,147,0.3);
  border-bottom: 22px solid transparent;
  position: absolute;
}

  .login-box{
    width: 600px;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin: 0 auto;
    padding-bottom: 30px;
  }
  .form-title{
    font-family: 'source_sans_prolight';
    font-size: 22px;
    color: black;
    text-align: left;
    padding-left: 10px;
  }
  input[type='checkbox']{
    display: inline ;
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
    vertical-align: middle;
  }


  .form-md {
    border-radius: 12px;
    border-color: rgba(129,137,147,0.3);
    border-style: solid;
    border-width: 0.5px;
    background-color: rgba(255,255,255,1);
    min-height: 290px;
    max-height: 400px;
    display: flex;
    flex-flow: column;
    &.forgot-password{
      min-height: 150px;
    }
    &.change-password {
      min-height: 250px;
    }
  }

  @media only screen and (max-width: 600px){
    .login-box{ width:100%;}

  }

  @media only screen and (max-height: 600px){
    .login-box{ width:100%; margin-top: 20px;}

  }

  .form-md .form-group {
    max-width: 100%;
  }
  .form-group {
    margin-bottom: 0px;
    padding-top: 15px;

    position: relative;
  }
  .button{
    width: 100%;
    font-family: 'source_sans_probold';
  }

  input {
    bottom: 0;
    position: relative;
    display: block;
    width: 99%;
    border: none;
    border-bottom: 1px solid rgba(129,137,147,0.3);
    background-color: transparent;
    margin: 0px auto;
    padding: 20px 0px 0px 25px;
    height: 52px;
    outline: none !important;
    font-size: 18px;
    font-family: 'source_sans_proregular';
    color: #40454C;
    background: -webkit-linear-gradient(bottom, #005394 50%, #005394 50%);
    background: linear-gradient(to top, #005394 50%, #005394 50%);
    background-position: left bottom;
    background-size: 0 1px;
    background-repeat: no-repeat;
    transition: all .4s ease-in-out;
  }




  input:after {
    width: 60px;
    height: 30px;
    border-bottom: solid 30px rgb(200,30,50);
    border-left: solid 30px transparent;
    border-right: solid 30px transparent;
  }

  ::-webkit-input-placeholder { text-align: right; color: #DDD; font-size: 13px; }
  :-moz-placeholder { text-align: right; color: #DDD; font-size: 13px; }
  ::-moz-placeholder { text-align: right; color: #DDD; font-size: 13px;  }
  :-ms-input-placeholder { text-align: right; color: #DDD; font-size: 13px;}

  .form-group label {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 20px;
    padding-top: 0px;
    text-align: left;
    display: block;
    width: 100%;
    height: 50px;
    line-height: 72px;
    text-transform: uppercase;
    font-size: 18px;
    font-family: 'source_sans_probold';
    background: transparent;
    color: #40454C;
    margin: 0px auto;
    cursor: text;
    transition: all .15s ease-in-out;    
    pointer-events: none;
  }
  input:hover, input:focus { border-color: #005394; }
  input:focus {
    background-position: left bottom;
    background-size: 100% 1px;
  }
  input:valid + label, input:focus + label {
    height: 20px;
    line-height: 20px;
    font-size: 18px;
    color: #40454C;
    font-family: 'source_sans_probold';
    padding-top: 10px;
  }

  input:focus ::-webkit-input-placeholder { color: transparent; font-size: 0; }
  input:focus :-moz-placeholder { color: transparent; font-size: 0; }
  input:focus ::-moz-placeholder { color: transparent; font-size: 0; }
  input:focus :-ms-input-placeholder { color: transparent; font-size: 0; }

  .siteNav-logo { display: inline; }
  .svgIcon-use { vertical-align: middle; }
  .svgIcon--logoNew path:nth-child(1) { fill: #d0d2d3; }
  .svgIcon--logoNew path:nth-child(2) { fill: #a6a8ab; }
  .svgIcon--logoNew path:nth-child(3) { fill: #808184; }
  .svgIcon--logoNew path:nth-child(4) { fill: #58595b; }

// password strenght
.password_length {
  margin-top: 15px;
  padding: 2px 10px;
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #eee;
  color: rgba(71, 87, 98, .8);
  border-radius: 4px;
  font-size: 13px;
  display: none;
  -webkit-transition: all .1s;
  transition: all .1s;
}

.valid_password_length {
  background: #50c8e8;
  color: rgba(255, 255, 255, .9);
}

.show_password_length {
  display: block;
}

.lnu_container {
  display: block;
  margin: 0px auto 10px auto;
  width: 100%;
  height: 30px;
  display: table;
  justify-content: space-between;
}

.lnu_container p {
  text-align:center;
  vertical-align: middle;
  display: table-cell;


  width: 35px;
  height: auto;
  font-size: 12px;
  line-height: 1.2;
  text-align: center;
  border-radius: 2px;
  color: rgba(71, 87, 98, .8);
  background: linear-gradient(to right, #50c8e8 50%, #eee 50%);
  background-size: 201% 100%;
  background-position: right;
  -webkit-transition: background .3s;
  transition: background .3s;


}

.special_valid,
.lovercase_valid,
.number_valid,
.uppercase_valid,
.same_password_valid,
.min_length_valid {
  background-position: left !important;
  color: rgba(255, 255, 255, .9) !important;
}

.valid_password_container {
  display: block;
  margin: 10px auto;
  border-radius: 4px;
  position: relative;
  background: #00AD7C;
  width: 20px;
  height: 20px;
  visibility: hidden;
  opacity: 0;
}

.show_valid_password_container {
  visibility: visible;
  opacity: 1;
}

.tick {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: white;
  stroke-width: 25;
  stroke-linecap: round;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
}

.checked {
  -webkit-animation: draw 0.5s ease forwards;
  animation: draw 0.5s ease forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
