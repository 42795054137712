.promotion-info{
    display: flex;
    flex-flow: wrap;
    line-height: 2;

    @include reverse-breakpoint($medium) {
        width: 100%;
        margin-top: unset;
        position: initial;
    }

    @include reverse-breakpoint($small) {
        position: initial;
    }

    .promotion-info__block{
        padding-left: 5px;
        padding-right: 5px;

        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
        }

        .promotion-info__element {
            display: flex;
            flex-flow: column;
            justify-content: space-around;
            width: 100%;
        }

        .promotion__startDate{
            @include reverse-breakpoint($medium) {
                width: 50%;
                padding-right: 5px;
            }
        }

        .promotion__endDate {
            @include reverse-breakpoint($medium) {
                width: 50%;
                padding-left: 5px;
            }
        }

        &--1{
            flex-grow: 1;
            @include reverse-breakpoint($medium) {
                display: flex;
                flex-flow: wrap;
                padding-left: 0;
                padding-right: 0;
                width: 100%;
            }
        }
        &--2{
            flex-grow: 1;
            @include reverse-breakpoint($medium) {
                display: flex;
                flex-flow: wrap;
                padding-left: 0;
                padding-right: 0;
                width: 100%;
            }
        }
        &--3{
            flex-grow: 1;
            min-width: 250px;
            @include reverse-breakpoint($medium) {
                display: flex;
                flex-flow: wrap;
                padding-left: 0;
                padding-right: 0;
                width: 100%;
            }
        }
        &--4{
            flex-grow: 0.5;
            min-width: 250px;
            @include reverse-breakpoint($medium) {
                padding-right: 0;
                width: 100%;
            }
            @include reverse-breakpoint($small) {
                padding-left: 0;
                min-width: unset;
            }
        }
    }

    .promotion-info__row {
        display: flex;
        flex-flow: row;

        .row-element {
            display: flex;
            flex-flow: column;

            &:last-child {
                padding-left: 5px;
            }

            &:first-child {
                padding-right: 5px;
            }

            @for $i from 1 through 100 {
                &--#{$i} {
                    width: $i * 1%;
                }
            }
        }
    }

    label {        
        display: flex;
        align-items: center;
        font-family: 'HelveticaNeue';
        letter-spacing: 0.5px;
        font-size: 12px;
        font-weight: bold;
        padding-bottom: 5px;
    }

    .promotion-info--bold {
        font-weight: bold;
        color: palette('grey', 'dark');
    }
    
    .date{
        color: palette('red','base');
    }
}
