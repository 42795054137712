.slider-box {
  position: relative;
  width: 100%;
}

.slider-list {
  width: 100%;
}

.slider-container {
  display: -webkit-inline-box;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}
  
.slider-button-slide {
  display: none;
  align-items: center;
  position: absolute;
  top: calc(50% - 20px);
  cursor: pointer;
  width: min-content;
  
  &.previous {
    left: -20px;

    i {
      padding-top: 3px;
    }
  }
  &.next {
    right: -20px;

    i {
      padding-top: 3px;
    }
  }

  @include reverse-breakpoint($small) {
    display: none;
  }
}
.slider-box:hover {
  .slider-button-slide {
    display: flex;
    @include reverse-breakpoint($small) {
      display: none;
    }
  }
}

.slider-button-slide > a {
  border-radius: 25px;
  background-color: #fff;
  border: solid 1px #e8e8e7;
  color: #2c2f34;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

ul.slider-container {
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: palette('white', 'base');
  scrollbar-gutter: stable;
  overflow: overlay;
}

.slider-list-element {
  -webkit-box-shadow: 3px 3px 3px palette('grey', 'light');
  box-shadow: 0px 2px 5px palette('grey', 'light');  
  height: 150px;
  width: calc(100% / 5);
  @include reverse-breakpoint($small) {
    height: 145px;
  }    
  @include reverse-breakpoint($xsmall) {
    width: calc(100% / 3);
  }    
}