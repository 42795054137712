/* information-box*/

.information-box {
  display: flex;
  flex-flow: wrap;
  align-items: flex-start;
  justify-content: center;
  background-color: #FFF;
  padding: 10px 20px;
  height: 100%;  
  cursor: pointer;
  text-decoration: none;
  color: #000;
  @include reverse-breakpoint($small) {
    padding: 10px 5px;
  }
}

.information-title-box {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.information-icon {
  display: flex;
  align-items: center;
  padding-right: 5px;

  i {
    font-size: 20px;
  }

  @include reverse-breakpoint($small) {
    i {
      font-size: 15px;
    }
  }
}

.information-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @include reverse-breakpoint($small) {
    padding-left: 2px;
  }
}

.information-row {
  width: 100%;
  text-align: center;

  .span-information{
    font-family: 'source_sans_proregular';
    font-size: 14px;
    color: palette('grey', 'semiLight');

    @include reverse-breakpoint($small) {
      font-size: 11px;
    }
  }
  
  .information-description {
    font-weight: bold;
    color: palette('blue', 'base');
  }
  
  .information-title > span {
    color: #000;
  }

  .span-bold{
    font-weight: bold;
  }

  .information-title > span:empty::before {
    content: "\200b";
  }
}