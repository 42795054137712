
        @import '@/core/assets/scss/abstracts/__variables.scss';
        @import '@/core/assets/scss/abstracts/_animations.scss';
        @import '@/core/assets/scss/abstracts/_fonts.scss';
        @import '@/core/assets/scss/abstracts/_functions.scss';
        @import '@/core/assets/scss/abstracts/_mixins.scss';
        @import '@/core/assets/scss/abstracts/_placeholders.scss';
        
.container-list {
    font-size: 18px;

    .container-list-group, .container-list-columns, .container-list-top {
        padding: 5px 0;
    }

    .container-list-columns {
        .container-list-column {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-bottom: 5px;

            .container-list-column-picker {
                flex-grow: 1;
            }

            .order-selected {
                color: white;
                background-color: rgb(0, 83, 148);
                border-color: initial;
            }
        }
        .container-list-column-buttons{
            display: flex;
        }
    }

    .container-list-column-add {
        padding-right: 88px;
    }

    .container-list-top {
        .container-list-top-group {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: left;

            .container-list-top-group-line {
                .container-list-top-group-line-input {
                    padding: 12px;
                }
            }

            .container-list-top-group-no {
                padding-left: 15px;

                .container-list-top-group-no-label {
                    margin-left: 5px;
                }
            }
        }
    }
}