.context-menu-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.span-context-menu {
  cursor: pointer;
  padding: 0 5px;
  font-size: 20px;
  line-height: 0;
}

.context-menu-content {
  top: 15px;
  left: 30px;
  position: absolute;
  background-color: #f5f7f7;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 5;
  color: palette('grey', 'dark');
  font-size: 14px;
  border: 1px solid;
  border-color: #e0e0e0;

  &.open-top {
    top: unset;
    bottom: 0;
  }

  &.open-right {
    left: unset;
    right: 35px;
  }
}

.context-menu-element {
  display: flex;
  flex-flow: wrap;
  cursor: pointer;

  .element-button {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    padding: 10px;
    min-width: 120px;
    width: max-content;

    span {
      padding: 0 5px 0 10px;
    }
  }

  .seperator {
    width: 100%;
    padding: 10px 3px;

    hr {
      border-top: solid 2px palette('grey', 'dark');
    }
  }
}

.context-menu-element:hover, 
.context-menu-element.element-button:hover {
  background-color: palette('grey', 'light');
}

.context-menu-element.disabled:hover, 
.context-menu-element .seperator:hover, 
.context-menu-element .element-button:disabled:hover {
  background-color: #f5f7f7;
  cursor: initial;
}